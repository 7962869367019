import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { Dropdown, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { user } from '../../store/user';

export const UserDropdown = ({ user: userData }) => {
  const setUserData = useSetRecoilState(user);
  const items = [
    {
      key: '1',
      label: <Link to='/bookings'>My Bookings</Link>,
    },
    {
      key: '2',
      label: <Link to='/profile'>Profile</Link>,
    },
    {
      key: '3',
      label: (
        <div
          onClick={() => {
            setUserData(null);
            localStorage.removeItem('user');
          }}
        >
          Logout
        </div>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <Avatar
            style={{ backgroundColor: '#11a5ea' }}
            icon={<UserOutlined />}
          />
          {userData.firstname}
        </Space>
      </a>
    </Dropdown>
  );
};
