import React, { useState, useRef, useEffect } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

import './GooglePlacesAutocomplete.css';
import { useOutsideClickDetector } from '../../utils/hooks';
import { notification } from 'antd';

export const GooglePlacesAutocomplete = ({ getBounds, add, locationUser }) => {
  const [address, setAddress] = useState('');
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setAddress(add);
  }, [add]);

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    const geocode = await geocodeByAddress(address);
    getBounds(geocode[0]);
    setAddress(geocode[0].formatted_address);
    setIsActive(false);
  };

  const handleOutsideClick = () => {
    // Perform any action you want when clicked outside
    setIsActive(false);
  };

  // Using the custom hook
  useOutsideClickDetector(inputRef, handleOutsideClick);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            onFocus={() => setIsActive(true)}
            ref={inputRef}
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
            })}
          />
          {suggestions.length || isActive ? (
            <div
              className="autocomplete-dropdown-container"
              style={{
                top:
                  inputRef.current.getBoundingClientRect().top > 250
                    ? 'auto'
                    : '100%',
                bottom:
                  inputRef.current.getBoundingClientRect().top > 250
                    ? '70%'
                    : 'auto',
                zIndex: 1,
              }}
            >
              <div
                className="autocomplete-dropdown-container-item-near"
                onClick={() => {
                  if (locationUser.code && locationUser.code === 1) {
                    notification.error({
                      message: `Please enable your browser's location to find hotels near you. If you prefer not to share your location, you can manually search for a place.`,
                      duration: 10,
                    });
                  } else {
                    getBounds(null, true);
                    setAddress('Near me');
                  }
                }}
              >
                <span>Near me</span>
              </div>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    className="autocomplete-dropdown-container-item"
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      )}
    </PlacesAutocomplete>
  );
};
