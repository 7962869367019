import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockFilled,
  LockOutlined,
} from '@ant-design/icons';
import { Input, Button, notification } from 'antd';
import * as Yup from 'yup';
import axiosInstance from '../../utils/axios';
import { useNavigate, useParams } from 'react-router-dom';

export const ForgotPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [buttonLoading, setbuttonLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      repeatPassword: '',
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string().required('New Password is a required field.'),
      repeatPassword: Yup.string()
        .required('Repeat password a is required field.')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      setbuttonLoading(true);
      // Handle form submission
      axiosInstance
        .post('api/v1/auth/forgot-password/confirm', {
          password: values.newPassword,
          token: params.token,
        })
        .then((res) => {
          if (res?.data?.results) {
            formik.resetForm();
            notification.success({
              message: 'Password is successfully updated!',
              description:
                'You are now ready to login with the new credentials.',
            });
          }
          setbuttonLoading(false);
          navigate('/');
        });
    },
  });

  return (
    <div
      style={{
        padding: '150px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '350px',
      }}
    >
      <h3>Update your password</h3>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label htmlFor='newPassword'>New password:</label>
          <Input.Password
            size='large'
            prefix={<LockOutlined />}
            type='newPassword'
            id='newPassword'
            name='newPassword'
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.newPassword}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor='repeatPassword'>Repeat password:</label>
          <Input.Password
            size='large'
            prefix={<LockOutlined />}
            type='repeatPassword'
            id='repeatPassword'
            name='repeatPassword'
            value={formik.values.repeatPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {formik.touched.repeatPassword && formik.errors.repeatPassword && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.repeatPassword}
            </div>
          )}
        </div>
        <br />
        <Button type='primary' loading={buttonLoading} htmlType='submit'>
          Update Passowrd
        </Button>
      </form>
    </div>
  );
};
