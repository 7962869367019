import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import { Spin } from 'antd';
import './CancelBooking.css';

export const CancelBooking = () => {
  const params = useParams();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axiosInstance
      .post('api/v1/hotels/booking/cancel', {
        token: params.token,
        booking_number: params.booking_id,
      })
      .then((res) => {
        if (res.data?.message) {
          setError(res.data?.message);
        }

        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          padding: '150px',
          height: '30%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size='large' />
      </div>
    );
  }

  if (error) {
    return (
      <div className='confirmation-email'>
        <div className='confirmation-email-title'>
          Booking Cancellation Info.
        </div>
        <br />

        <br />
        <p>{error[0]}</p>
        <br />
        <p>Booking number: #{params.booking_id}</p>
        <div>
          <br />
          <br />
          <div>
            Please contact us if you have any questions or check our
            booking policy here:{' '}
            <Link to='/booking-policy'>Booking policy</Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='confirmation-email'>
      <div className='confirmation-email-title'>
        Booking Cancellation Confirmation.
      </div>
      <br />
      <p>
        We have received your request to cancel your booking. We're sorry to see
        you go.
      </p>
      <p>
        Please note that any applicable cancellation fees or refund policies
        mentioned in the terms and conditions of your booking may apply. If you
        have any further questions or concerns, please don't hesitate to contact
        our customer support.
      </p>
      <br />
      <p>Booking number: #{params.booking_id}</p>
      <div>
        <br />
        <br />
        <div>
          Thank you for considering our services, and we hope to have the
          opportunity to serve you in the future!
        </div>
      </div>
    </div>
  );
};
