import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import './Terms.css';
import { Search } from '../Landing/components/Search/Search';

export const Terms = () => {
  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Terms',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  return (
    <div className='terms-page'>
      <div className='hotels-search'>
        <Search />
      </div>
      <div class='sqs-block-content terms-page-content '>
        <h2>
          <strong>
            <span style={{ fontSize: '18.0pt' }}>NAPPR – TERMS OF SERVICE</span>
          </strong>
        </h2>
        <p>
          The terms and conditions stated herein (collectively, the Agreement)
          constitute a legal agreement between you and Forty Winks LLC, an
          American corporation (the Company or We) having its registered office
          at 211 West 56th Street, Apt. 20B, New York, NY 10019. In order to use
          the Nappr service and the associated Application and Website (each as
          defined below) you must agree to the terms and conditions that are set
          out below, including without limitation (for U.S.-based users) your
          agreement to arbitrate certain claims and not to participate in any
          class actions for claims subject to arbitration. By using the
          Company's Nappr services and downloading, installing or using the
          application supplied by the Company which purpose is to enable you to
          host and rent nap spaces, you hereby expressly acknowledge and agree
          to be bound by the terms and conditions of the Agreement, and any
          future Changes to this Agreement (as set forth below). You further
          acknowledge and agree that you have read and understand the Company's
          Privacy Policy <a href='https://www.nappr.io/privacy'>here</a> (the
          Privacy Policy). The Privacy Policy, which applies to your use of the
          Website and Nappr mobile application is hereby incorporated into this
          Agreement.
        </p>
        <p>
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT
          INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS.
          THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, A CLAUSE THAT
          GOVERNS THE JURISDICTION AND VENUE OF DISPUTES, AND OBLIGATIONS TO
          COMPLY WITH APPLICABLE LAWS AND REGULATIONS.
        </p>
        <p>
          IN PARTICULAR, HOSTS SHOULD UNDERSTAND HOW THE LAWS WORK IN THEIR
          RESPECTIVE CITIES. SOME CITIES HAVE LAWS THAT RESTRICT THEIR ABILITY
          TO HOST PAYING GUESTS FOR SHORT PERIODS. THESE LAWS ARE OFTEN PART OF
          A CITY'S ZONING OR ADMINISTRATIVE CODES. IN MANY CITIES, HOSTS MUST
          REGISTER, GET A PERMIT, OR OBTAIN A LICENSE BEFORE LISTING A PROPERTY
          OR ACCEPTING GUESTS. CERTAIN TYPES OF SHORT-TERM BOOKINGS MAY BE
          PROHIBITED ALTOGETHER. LOCAL GOVERNMENTS VARY GREATLY IN HOW THEY
          ENFORCE THESE LAWS. PENALTIES MAY INCLUDE FINES OR OTHER ENFORCEMENT.
          HOSTS SHOULD REVIEW LOCAL LAWS BEFORE LISTING A SPACE ON NAPPR.
        </p>
        <p>
          The Company may make modifications, deletions and/or additions to this
          Agreement (Changes) at any time. Changes will be effective: (i) thirty
          (30) days after the Company provides notice of the Changes, whether
          such notice is provided through the Application or Website user
          interface, is sent to the email address associated with your account
          or otherwise; or (ii) when you opt-in or otherwise expressly agree to
          the Changes or a version of this Agreement incorporating the Changes,
          whichever comes first.
        </p>
        <p>
          <strong>
            <span>
              Key Content-related Terms
              <br />
            </span>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <em>"Host Spaces"</em>
            </strong>
            <em> </em>means the physical locations of a host, including entry or
            access areas, beds and any common areas, as applicable, which are
            accessed through the Application, the Website or any other means
            that Nappr implements.
          </li>
          <li>
            <strong>
              <em>“Host”</em>
            </strong>
            <em>
              {' '}
              means a Member who creates a Listing via the Site, Application and
              Services.{' '}
            </em>
          </li>
          <li>
            <strong>
              <em>"Guest"</em>
            </strong>
            <em>
              {' '}
              means a Member who requests from a Host a booking of a Listing via
              the Site, Application or Services, or a Member who stays at an
              Accommodation and is not the Host for the associated Listing.
            </em>
          </li>
          <li>
            "<em>Collective Content"</em> means, collectively, Company Content
            and User Content.
          </li>
          <li>
            <strong>
              <em>"Content"</em>
            </strong>{' '}
            means text, graphics, images, software (excluding the Application),
            video, information or other materials.
          </li>
          <li>
            <strong>
              <em>"Company Content"</em>
            </strong>{' '}
            means Content that Company makes available through the Application
            and/or Website, including any Content licensed from a third party,
            but excluding User Content.
          </li>
          <li>
            <strong>
              <em>"User"</em>
            </strong>{' '}
            means a person who accesses or uses the Nappr Application or
            Website.
          </li>
          <li>
            <strong>
              <em>"User Content</em>
            </strong>
            <em>"</em> means Content that a User posts, uploads, publishes,
            submits or transmits to be made available through the Website or
            Application.
          </li>
          <li>
            <strong>
              <em>"Website”</em>
            </strong>{' '}
            means Nappr.io or any other website through which the Company makes
            available the ability to use the application to host and book spaces
            and posts this Agreement
          </li>
        </ul>
        <p>
          <strong>
            <span>Use</span>
          </strong>
        </p>
        <p>
          In order to be able to use the Website and Application you first need
          to sign up with the Company, which you can do through the Application.
          When signing up, you are obligated to provide the Company with your
          personal information and PayPal data. Upon successful completion of
          your sign-up with the Company, You will be provided with a personal
          account (an “Account”), accessible to you by a password of your
          choice. You agree to maintain accurate, complete and up-to-date
          information in your Account. Your failure to maintain accurate,
          complete, and up-to-date account information, including having an
          invalid or expired payment method on file, may result in your
          inability to access and use the Website and Application.
        </p>
        <p>
          As further described in the Privacy Policy, the Company uses PayPal
          ("The Payment Processor") to link your account to the Website and
          Application. The processing of payments or credits, as applicable, in
          connection with your use of the Website and Application will be
          subject to the terms, conditions and privacy policies of the Payment
          Processor in addition to this Agreement. The Company is not
          responsible for any errors by the Payment Processor.
        </p>
        <p>
          You agree to be bound by and comply with any additional terms,
          conditions and policies provided by the owner, tenant, and property
          manager of the Host Spaces (collectively, the Building Owner/Renters)
          relating to the use of a specific Nappr-listed Space(s), including
          compliance with building security procedures, IT access and use
          procedures provided by the Building Owner/Renter (Venue Policies). The
          Venue Policies may be provided in electronic format through the
          Application, Website or in hardcopy format.
        </p>
        <p>
          You are responsible for all activity that occurs with respect to your
          Account, including, without limitation, use of the Website,
          Application, and Host Spaces. Without limiting the foregoing, for any
          reservation made through your Account, if other persons are present in
          the Host spaces, you do hereby agree to be fully responsible and to
          indemnify the Company for any violation of this Agreement or
          applicable laws, by-laws or regulations, even if such violation was
          caused by such other persons.
        </p>
        <p>
          THE WEBSITE, APPLICATION AND SERVICES COMPRISE AN ONLINE PLATFORM
          THROUGH WHICH HOSTS MAY CREATE LISTINGS FOR ACCOMMODATIONS AND GUESTS
          MAY LEARN ABOUT AND BOOK ACCOMMODATIONS DIRECTLY WITH THE HOSTS. YOU
          UNDERSTAND AND AGREE THAT NAPPR IS NOT A PARTY TO ANY AGREEMENTS
          ENTERED INTO BETWEEN HOSTS AND GUESTS, NOR IS NAPPR A REAL ESTATE
          BROKER, AGENT OR INSURER. NAPPR HAS NO CONTROL OVER THE CONDUCT OF
          HOSTS, GUESTS AND OTHER USERS OF THE SITE, APPLICATION AND SERVICES OR
          ANY ACCOMMODATIONS, AND DISCLAIMS ALL LIABILITY IN THIS REGARD TO THE
          MAXIMUM EXTENT PERMITTED BY LAW.
        </p>
        <p>
          IF YOU CHOOSE TO CREATE A LISTING ON NAPPR, YOU UNDERSTAND AND AGREE
          THAT YOUR RELATIONSHIP WITH NAPPR IS LIMITED TO BEING A MEMBER AND AN
          INDEPENDENT, THIRD-PARTY CONTRACTOR, AND NOT AN EMPLOYEE, AGENT, JOINT
          VENTURER OR PARTNER OF NAPPR FOR ANY REASON, AND YOU ACT EXCLUSIVELY
          ON YOUR OWN BEHALF AND FOR YOUR OWN BENEFIT, AND NOT ON BEHALF OF OR
          FOR THE BENEFIT OF NAPPR. NAPPR DOES NOT CONTROL, AND HAS NO RIGHT TO
          CONTROL, YOUR LISTING, YOUR OFFLINE ACTIVITIES ASSOCIATED WITH YOUR
          LISTING, OR ANY OTHER MATTERS RELATED TO ANY LISTING, THAT YOU
          PROVIDE. AS A MEMBER YOU AGREE NOT TO DO ANYTHING TO CREATE A FALSE
          IMPRESSION THAT YOU ARE ENDORSED BY, PARTNERING WITH, OR ACTING ON
          BEHALF OF OR FORTHE BENEFIT OF NAPPR, INCLUDING BY INAPPROPRIATELY
          USING ANY NAPPR INTELLECTUAL PROPERTY.
        </p>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE SITE,
          APPLICATION OR SERVICES OR BY DOWNLOADING OR POSTING ANY CONTENT FROM
          OR ON THE SITE, VIA THE APPLICATION OR THROUGH THE SERVICES, YOU ARE
          INDICATING THAT YOU HAVE READ, AND THAT YOU UNDERSTAND AND AGREE TO BE
          BOUND BY THESE TERMS AND RECEIVE OUR SERVICES WHETHER OR NOT YOU HAVE
          REGISTERED WITH THE SITE AND APPLICATION. IF YOU DO NOT AGREE TO THESE
          TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SITE, APPLICATION,
          SERVICES, OR COLLECTIVE CONTENT. If you accept or agree to these Terms
          on behalf of a company or other legal entity, you represent and
          warrant that you have the authority to bind that company or other
          legal entity to these Terms and, in such event, "you" and "your" will
          refer and apply to that company or other legal entity.
        </p>
        <p>
          <strong>
            <span>Representations and Warranties</span>
          </strong>
        </p>
        <p>
          By using the Website and Application, you expressly represent and
          warrant that you are legally entitled to enter into this Agreement. If
          you reside in a jurisdiction which restricts the use of short-term
          dwelling rentals because of age, or restricts the ability to enter
          into agreements such as this one due to age, you must abide by such
          age limits and you must not use the Website or Application. Without
          limiting the foregoing, the Website and Application are not available
          to persons under the age of 18. By using the Website and Application,
          you represent and warrant that you are at least 18 years old. By using
          the Website or Application, you represent and warrant that you have
          the right, authority and capacity to enter into this Agreement and to
          abide by the terms and conditions of this Agreement. Your
          participation in using the Website and/or Application is for your
          sole, personal use. You may not authorize others to use your user
          status and/or password, and you may not assign or otherwise transfer
          your user account and/or password to any other person or entity. When
          using the Website or Application, you agree to comply with all
          applicable laws of the nation, the country, state, province and city
          in which you are present while using the Website or Application.
        </p>
        <p>
          You may only access the Host Spaces using authorized means. It is your
          responsibility to check to ensure you download the correct Application
          for your device. The Company is not liable if you do not have a
          compatible handset or if you have downloaded the wrong version of the
          Application for your handset. The Company reserves the right to
          terminate this Agreement should you be using the Application with an
          incompatible or unauthorized device.
        </p>
        <p>By using the Website or Application, you agree that:</p>
        <p>
          <strong>ALL USERS:</strong>
        </p>
        <ul>
          <li>
            You will only use the Website and Application for lawful purposes.
          </li>
          <li>
            You will not use the Website or Application for any illegal or
            inappropriate purposes, including but not limited to skateboarding,
            roller-skating, roller blading, jumping rope, spitting, smoking,
            drug use, alcohol abuse, gambling or prostitution, pornography,
            sexual activity, violent or threatening behavior, or any other
            purpose reasonably likely to reflect negatively on Nappr or any
            User.
          </li>
          <li>You will not impair the proper operation of the network.</li>
          <li>
            You will not try to harm the Website or Application in any way
            whatsoever.
          </li>
          <li>
            You will not copy, or distribute the Website, Application or other
            content without written permission from the Company.
          </li>
          <li>
            You will not use the Website or Application for sending or storing
            any unlawful material or for fraudulent purposes.
          </li>
          <li>
            You will not use the Website or Application to cause nuisance,
            harassment, annoyance or inconvenience.
          </li>
          <li>
            You will only use your password for the Website, Application and
            Host Spaces for your own use and will not resell or transfer it to a
            third party.
          </li>
        </ul>
        <p>
          <strong>GUESTS:</strong>
        </p>
        <ul>
          <li>You will not smoke in the Host Spaces.</li>
          <li>
            You will not light or allow any candles, incense sticks or naked
            flames in the Host Spaces.
          </li>
          <li>
            You will not bring or keep any animals in the Host Spaces, except
            for service or assistance animals, which shall at all times be
            harnessed, leashed, or tethered, or controlled through voice,
            signal, or other effective controls.
          </li>
          <li>
            You will not make excessive noise in the Host Spaces, nor create any
            noise nor conduct any other activity which would in Nappr's or any
            Building Owner/Renter's judgment disturb other Nappr clients,
            guests, or other tenants in any building containing a Host Space.
          </li>
          <li>
            You will follow all additional regulations regarding the Host
            Spaces, as may be communicated through the Application, Website,
            posted signs, or otherwise.
          </li>
          <li>
            You will not install, remove or modify any fixtures, equipment,
            machinery or appliances in the Host Spaces.
          </li>
          <li>
            You are responsible for leaving the Host Spaces in a clean and tidy
            condition.
          </li>
          <li>
            Neither the Company nor any Building Owner/Renter is responsible for
            any property you may leave behind in a Host Space. It is your
            responsibility to ensure that you have retrieved all of your
            personal items prior to leaving a Host Space.
          </li>
          <li>
            You may be held liable (and do hereby authorize the Company to
            charge you) for the repair cost for all damage to the Host Spaces
            and items therein during your use thereof.
          </li>
          <li>
            You may be held liable (and do hereby authorize the Company to
            charge you) for any items which are taken from the Host Spaces.
          </li>
          <li>
            You will keep secure and confidential your account password or any
            identification we provide you which allows access to the Host
            Spaces.
          </li>
          <li>
            You will provide the Company and/or the Building Owner/Renter with
            whatever proof of identity we/they may reasonably request.
          </li>
          <li>
            You will only use an access point or wireless internet connection
            which you are authorized to use. Additionally, when using the
            internet within the Host Spaces, you shall only use it for lawful
            purposes, and without restricting the foregoing, you shall not use
            it for purposes of criminal acts, consumer fraud, etc.
          </li>
          <li>
            You will wear clothing appropriate to the building's situation while
            using the Host Spaces.
          </li>
          <li>
            You do hereby acknowledge that there is a capacity limit as to the
            number of persons permitted in a Host Space at any given time, and
            that such limit shall be posted on the Application or Website. You
            do hereby agree to adhere to such capacity limit.
          </li>
          <li>
            You hereby consent to the use of sensors by the Company to track the
            location of various items in the Host Spaces, which (without
            limitation) may be used by the hosts to optimize the Host Spaces.
          </li>
        </ul>
        <p>
          You do further acknowledge that your use of the Host Spaces does not
          constitute the Company or the Building Owner/Renter granting you a
          lease, but is rather a limited, revocable, non-exclusive,
          non-transferable contractual license on the terms of this Agreement.
          In its sole discretion and without limiting any of the Company's other
          rights hereunder, the Company may restrict your access to the Host
          Spaces in the event of fraud, trespassing, or violation of this
          Agreement.
        </p>
        <p>
          <strong>HOSTS:</strong>
        </p>
        <ul>
          <li>
            You are responsible for ensuring that Host Spaces are clean and
            suitable for any arriving Guests.
          </li>
          <li>
            You will not post misleading or inaccurate descriptions of your Host
            Spaces.
          </li>
          <li>
            You alone are responsible for any and all Listings and Member
            Content you post.
          </li>
          <li>
            You understand and agree that Nappr does not act as an insurer or as
            your contracting agent.
          </li>
          <li>
            You acknowledge that any agreement that you enter into with a Guest
            is between you and the Guest and Nappr is not a party to it.
          </li>
          <li>
            You acknowledge and agree that, as a Host, you are responsible for
            your own acts and omissions and are also responsible for the acts
            and omissions of any individuals who reside at or are otherwise
            present at the Accommodation at your request or invitation,
            excluding the Guest (and the individuals the Guest invites to the
            Accommodation, if applicable.)
          </li>
        </ul>
        <p>
          Any Listing you post and the booking of, or a Guest's stay at, an
          Accommodation in a Listing you post (i) will not breach any agreements
          you have entered into with any third parties, such as homeowners
          association, condominium, lease or rental agreements, and (ii) will
          (a) be in compliance with all applicable laws (such as zoning laws and
          laws governing rentals of residential and other properties), Tax
          requirements, Intellectual Property laws, and rules and regulations
          that may apply to any Accommodation included in a Listing you post
          (including having all required permits, licenses and registrations),
          and (b) not conflict with the rights of third parties.
        </p>
        <p>
          Please note that Nappr assumes no responsibility for a Host's
          compliance with any agreements with or duties to third parties,
          applicable laws, rules and regulations. Nappr reserves the right, at
          any time and without prior notice, to remove or disable access to any
          Listing for any reason, including Listings that Nappr, in its sole
          discretion, considers to be objectionable for any reason, in violation
          of these Terms or Nappr's then-current Policies and Community
          Guidelines or Standards, Trademark &amp; Branding Guidelines, or
          otherwise harmful to the Site, Application or Services.
        </p>
        <p>
          <strong>
            <span>Repair or Cleaning Fees</span>
          </strong>
        </p>
        <p>
          As a Guest, you are responsible for the cost of repair for damage to,
          or necessary cleaning of, Host Spaces resulting from your violation of
          this Agreement or your use of the Website, Application, or Host Spaces
          in excess of normal “wear and tear.” In the event that the Company, in
          its reasonable discretion, determines that excessive repair or
          cleaning is required, the Company reserves the right to charge the
          payment method designated in your Account for the reasonable cost of
          such repair and/or cleaning, as well as an additional service fee
          which shall not exceed in Canada $500 Canadian Dollars, in the United
          States of America $500 US Dollars and in the UK £500 GBP per
          occurrence. Any such amounts are non-refundable and at the reasonable
          discretion of the Company.
        </p>
        <p>
          <strong>
            <span>License Grant, Restrictions and Copyright Policy</span>
          </strong>
        </p>
        <p>
          Subject to your compliance with the terms and conditions of this
          Agreement, Company grants you a limited, revocable, non-exclusive,
          non-transferable license to view any User Content to which you are
          permitted access solely for your personal and non-commercial purposes.
          You have no right to sublicense or assign the license rights granted
          in this section.
        </p>
        <p>
          You will not use, copy, adapt, modify, decompile, reverse engineer,
          prepare derivative works based upon, distribute, license, sell,
          transfer, publicly display, publicly perform, transmit, stream,
          broadcast or otherwise exploit the Website, Host Spaces, Application
          or Collective Content, except as expressly permitted in this
          Agreement. No licenses or rights are granted to you by implication or
          otherwise under any intellectual property rights owned or controlled
          by Company or its licensors, except for the licenses and rights
          expressly granted in this Agreement or expressly agreed in writing by
          the Company.
        </p>
        <p>
          <strong>
            <span style={{ fontSize: '14.0pt' }}>License granted by User</span>
          </strong>
        </p>
        <p>
          The Company may, in our sole discretion, permit Users to post, upload,
          publish, submit or transmit User Content. By making available any User
          Content on or through the Website or Application, you hereby grant to
          Company a worldwide, irrevocable, perpetual, non-exclusive,
          transferable, royalty-free license, with the right to sublicense, to
          use, view, copy, adapt, modify, distribute, license, sell, transfer,
          publicly display, publicly perform, transmit, stream, broadcast and
          otherwise exploit such User Content including but without limitation
          on, through or by means of the Website or Application. In connection
          herewith, you hereby renounce and waive in favor of Company any moral
          rights you have or might have, now or in the future, with respect to
          User Content. Nothing in this Agreement will be deemed to restrict any
          rights that you may have to use and exploit any User Content.
        </p>
        <p>
          You acknowledge and agree that you are solely responsible for all User
          Content that you make available through the Website or Application.
          Accordingly, you represent and warrant that: (i) you either are the
          sole and exclusive owner of all User Content that you make available
          through the Website or Application or you have all rights, licenses,
          consents and releases that are necessary to grant to Company the
          rights in such User Content, as contemplated under this Agreement; and
          (ii) neither the User Content nor your posting, uploading,
          publication, submission or transmittal of the User Content or
          Company's use of the User Content (or any portion thereof) will
          infringe, misappropriate or violate a third party's patent, copyright,
          trademark, trade secret, moral rights or other intellectual property
          rights, or rights of publicity or privacy, or result in the violation
          of any applicable law or regulation.
        </p>
        <p>
          <strong>
            <span>Application License</span>
          </strong>
        </p>
        <p>
          Subject to your compliance with this Agreement, Company grants you a
          limited non-exclusive, non-transferable license to download and
          install a copy of the Application on any mobile device or computer
          that you own or control and to run such copy of the Application solely
          for your own personal use. Furthermore, with respect to any
          Application accessed through or downloaded from the Apple App Store
          (App Store Sourced Application), you will use the App Store Sourced
          Application only: (i) on an Apple-branded product that runs iOS
          (Apple's proprietary operating system software); and (ii) as permitted
          by the "Usage Rules" set forth in the Apple App Store Terms and
          Conditions. Company reserves all rights in and to the Application not
          expressly granted to you under this Agreement.
        </p>
        <p>
          <strong>
            <span>Accessing and Downloading the Application from iTunes</span>
          </strong>
        </p>
        <p>The following applies to any App Store Sourced Application:</p>
        <ul>
          <li>
            You acknowledge and agree that (i) this Agreement is concluded
            between you and Company only, and not Apple, and (ii) Company, not
            Apple, is solely responsible for the App Store Sourced Application
            and content thereof. Your use of the App Store Sourced Application
            must comply with the Apple App Store Terms and Conditions, which you
            are responsible to review from time to time.
          </li>
          <li>
            You acknowledge that Apple has no obligation whatsoever to furnish
            any maintenance and support services with respect to the App Store
            Sourced Application.
          </li>
          <li>
            In the event of any failure of the App Store Sourced Application to
            conform to any applicable warranty, you may notify Apple, and Apple
            will refund the purchase price for the App Store Sourced Application
            to you and to the maximum extent permitted by applicable law, Apple
            will have no other warranty obligation whatsoever with respect to
            the App Store Sourced Application. As between Company and Apple, any
            other claims, losses, liabilities, damages, costs or expenses
            attributable to any failure to conform to any warranty will be the
            sole responsibility of Company.
          </li>
          <li>
            You and Company acknowledge that, as between Company and Apple,
            Apple is not responsible for addressing any claims you have or any
            claims of any third party relating to the App Store Sourced
            Application or your possession and use of the App Store Sourced
            Application, including, but not limited to: (i) product liability
            claims; (ii) any claim that the App Store Sourced Application fails
            to conform to any applicable legal or regulatory requirement; and
            (iii) claims arising under consumer protection or similar
            legislation.
          </li>
          <li>
            You and Company acknowledge and agree that Apple, and Apple's
            subsidiaries, are third party beneficiaries of this Agreement as
            related to your license of the App Store Sourced Application, and
            that, upon your acceptance of the terms and conditions of this
            Agreement, Apple will have the right (and will be deemed to have
            accepted the right) to enforce this Agreement as related to your
            license of the App Store Sourced Application against you as a third
            party beneficiary thereof.
          </li>
          <li>
            Without limiting any other terms of this Agreement, you must comply
            with all applicable third party terms of agreement when using the
            App Store Sourced Application.
          </li>
        </ul>
        <p>
          You shall not (i) license, sublicense, sell, resell, transfer, assign,
          distribute or otherwise commercially exploit or make available to any
          third party the Website, Host Spaces or the Application in any way;
          (ii) modify or make derivative works based upon the Website, Host
          Spaces or the Application; (iii) create Internet "links" to, or
          "frame" or "mirror" the Website or Application on any other server or
          wireless or Internet-based device; (iv) reverse engineer the Website
          or Application, or access the Website or Application in order to (a)
          build a competitive product or service, (b) build a product using
          similar ideas, features, functions or graphics of the Website or
          Application, or (c) copy any ideas, features, functions or graphics of
          the Website or Application, or (v) launch an automated program or
          script, including, but not limited to, web spiders, web crawlers, web
          robots, web ants, web indexers, bots, viruses or worms, or any program
          which may make multiple server requests per second, or unduly burdens
          or hinders the operation and/or performance of the Website or
          Application.
        </p>
        <p>
          You shall not: (i) send spam or otherwise duplicative or unsolicited
          messages in violation of applicable laws; (ii) send or store
          infringing, obscene, threatening, libelous, or otherwise unlawful or
          tortious material, including material harmful to children or violative
          of third party privacy rights and material that could be considered as
          sexual or psychological harassment or of a discriminatory nature;
          (iii) send or store material containing software viruses, worms,
          Trojan horses or other harmful computer code, files, scripts, agents
          or programs; (iv) interfere with or disrupt the integrity or
          performance of the Website or Application or the data contained
          therein; or (v) attempt to gain unauthorized access to the Website or
          Application or its related systems or networks.
        </p>
        <p>
          Company will have the right to investigate and prosecute violations of
          any of the above to the fullest extent of the law. Company may involve
          and cooperate with law enforcement authorities in prosecuting users
          who violate this Agreement. You acknowledge that Company has no
          obligation to monitor your access to or use of the Website, Host
          Spaces, Application or Collective Content or to review or edit any
          Collective Content, but has the right to do so for the purpose of
          operating the Website, Host Spaces and Application, to ensure your
          compliance with this Agreement, or to comply with applicable law or
          the order or requirement of a court, administrative agency or other
          governmental body. Company reserves the right, at any time and without
          prior notice, to remove or disable access to any Collective Content
          that Company, at its sole discretion, considers to be in violation of
          this Agreement or otherwise harmful to the Website or Application.
        </p>
        <p>
          <strong>
            <span>Copyright Policy</span>
          </strong>
        </p>
        <p>
          The Company respects copyright law and expects its Users to do the
          same. It is Company's policy to terminate in appropriate circumstances
          Users or other account holders who repeatedly infringe or are believed
          to be repeatedly infringing the rights of copyright holders.
        </p>
        <p>
          <strong>
            <span>Payment Terms</span>
          </strong>
        </p>
        <ul>
          <li>
            <strong>
              <em>"Accommodation Fees"</em>
            </strong>{' '}
            means the amounts that are due and payable by a Guest in exchange
            for that Guest's stay in an Accommodation. The Host alone, and not
            Nappr, is responsible for the Accommodation Fees for his or her
            Listing. The Host may in his or her sole discretion decide to
            include in these amounts (i) a cleaning fee or any other fee
            permitted on the Nappr platform, or (ii) Taxes that the Host
            determines that he or she has to collect.
          </li>
          <li>
            <strong>
              <em>"Guest Fees"</em>
            </strong>{' '}
            means the fee that Nappr charges a Guest for the use of the
            Services, which is calculated as a percentage of the applicable
            Accommodation Fees. The Guest Fees will be displayed to the Guest
            when the Guest is asked whether to send a booking request to a Host.
          </li>
          <li>
            <strong>
              <em>"Host Fees"</em>
            </strong>{' '}
            means the fee that Nappr charges a Host for the use of the Services,
            which is calculated as a percentage of the applicable Accommodation
            Fees. The Host Fees will be displayed to the Host when the Host is
            asked whether to confirm or reject a booking request from a
            prospective Guest.
          </li>
          <li>
            <strong>
              <em>"Payment Method"</em>
            </strong>{' '}
            means a payment method that you have added to your Nappr Account,
            such as PayPal.
          </li>
          <li>
            <strong>
              <em>"Service Fees"</em>
            </strong>{' '}
            means collectively the Guest Fees and the Host Fees.
          </li>
          <li>
            <strong>
              <em>"Total Fees"</em>
            </strong>{' '}
            means collectively the Accommodation Fees and the Guest Fees plus
            any Taxes.
          </li>
        </ul>
        <p>
          Any fees which the Company may charge you for the use of the Website
          or Application, will be as set out on the Website or Application at
          the time of purchase, are inclusive of any taxes payable, are due
          immediately and are non-refundable. This no refund policy shall apply
          at all times regardless of your decision to terminate your usage, the
          Company's decision to terminate your usage, disruption caused to our
          Website or Application either planned, accidental or intentional, or
          any reason whatsoever. The Company reserves the right to determine
          final prevailing pricing.
        </p>
        <p>
          <strong>FOR HOSTS:</strong>
        </p>
        <p>
          If you are a Host and a booking is requested for your Accommodation
          via the Site, Application or Services, you will be required to either
          preapprove, confirm or reject the booking request within the Booking
          Request Period, otherwise the booking request will automatically
          expire. When a booking is requested via the Site, Application or
          Services, we will share with you (i) the first and last name of the
          Guest who has requested the booking, (ii) a link to the Guest's Nappr
          Account profile page, and (iii) an indication of whether or not the
          Guest has provided other information to Nappr, such as a verified
          email address, connection to SNSs, or a government ID. If you are
          unable to confirm or decide to reject a booking request within the
          Booking Request Period, any amounts collected by Nappr (via PayPal)
          for the requested booking will be refunded to the applicable Guest.
          When you confirm a booking requested by a Guest, Nappr will send you
          an email, text message or message via the Application confirming such
          booking, depending on the selections you make via the Site,
          Application and Services.
        </p>
        <p>
          Nappr will collect the Total Fees from Guests at the time of the
          booking request or upon the Host's confirmation and will initiate
          payment of the Accommodation Fees (less applicable fees and taxes) to
          the Host in most cases within 24 hours of the scheduled check-in time
          of the Guest at the applicable Accommodation (except to the extent
          that a refund is due to the Guest) and as further described in the
          Payments Terms.
        </p>
        <p>
          Each Host agrees that Nappr may, in accordance with the cancellation
          policy selected by the Host and reflected in the relevant Listing, (i)
          permit the Guest to cancel the booking and (ii) refund (via Nappr
          Payments) to the Guest that portion of the Accommodation Fees
          specified in the applicable cancellation policy.
        </p>
        <p>
          <strong>FOR GUESTS:</strong>
        </p>
        <p>
          The Hosts, not Nappr, are solely responsible for honoring any
          confirmed bookings and making available any Accommodations reserved
          through the Site, Application and Services. If you, as a Guest, choose
          to enter into a transaction with a Host for the booking of an
          Accommodation, you agree and understand that you will be required to
          enter into an agreement with the Host and you agree to accept any
          terms, conditions, rules and restrictions associated with such
          Accommodation imposed by the Host. You acknowledge and agree that you,
          and not Nappr, will be responsible for performing the obligations of
          any such agreements, that Nappr is not a party to such agreements, and
          that, with the exception of Nappr Payments' obligations pursuant to
          the Payments Terms, Nappr (inclusive of all subsidiaries) disclaims
          all liability arising from or related to any such agreements.
        </p>
        <p>
          The Total Fees payable will be displayed to a Guest before the Guest
          sends a booking request to a Host. As noted above, the Host is
          required to either preapprove, confirm or reject the booking request
          within the Booking Request Period; otherwise, the requested booking
          will be automatically cancelled. Upon receipt of your booking request,
          Nappr Payments may initiate a pre-authorization and/or charge a
          nominal amount to your Payment Method pursuant to the Payments Terms.
          If a requested booking is cancelled (i.e. not confirmed by the
          applicable Host), any amounts collected by Nappr Payments will be
          refunded to such Guest, depending on the selections the Guest makes
          via the Site and Application, and any pre-authorization of such
          Guest's Payment Method will be released, if applicable.
        </p>
        <p>
          You as a Guest agree to pay the Total Fees for any booking requested,
          and in most cases confirmed, in connection with your Nappr Account.
          Nappr Payments will collect the Total Fees pursuant to the Payments
          Terms.
        </p>
        <p>
          Once your confirmed booking transaction is complete you will receive a
          confirmation email summarizing your confirmed booking.
        </p>
        <p>
          <strong>ALL USERS:</strong>
        </p>
        <p>
          In consideration for the use of Nappr's online marketplace and
          platform, Nappr charges Service Fees. Nappr Payments collects these
          Service Fees pursuant to the Payments Terms, and, where applicable,
          may also collect Taxes (such as VAT in Europe) in respect of the Host
          Fees and Guest Fees. Nappr Payments deducts the Host Fees from the
          Accommodation Fees before remitting the balance to the Host as
          described in the Payments Terms. Guest Fees are, as noted above,
          included in the Total Fees.
        </p>
        <p>
          The Company, at its sole discretion, may make promotional offers with
          different features and different rates to any of our customers. These
          promotional offers, unless made to you, shall have no bearing
          whatsoever on your offer or contract. The Company may change the fees
          for our Website or Application as we deem necessary for our business.
          We encourage you to check back at our Website periodically to find out
          about how we charge for the Website or Application.
        </p>
        <p>
          <strong>
            <span>SMS Messaging</span>
          </strong>
        </p>
        <p>
          If you have provided your consent, Company may send you SMS messages
          in relation to your bookings and use of Host Spaces and for
          promotional purposes. Message and data rates may apply.
        </p>
        <p>
          If you change your mobile phone service provider, you might have to
          re-enroll with the Company in order to receive future SMS messages.
          Company reserves the right to stop sending SMS messages at any time;
          you may at all times opt-out by replying from your mobile phone to any
          text from the Company with the word STOP , which opt-out will become
          effective within seven (7) days.
        </p>
        <p>
          It is important to note that opting out of SMS messaging may prevent
          you from receiving information necessary to properly use the Nappr
          service, such as listing confirmations and other important
          information.
        </p>
        <p>
          <strong>
            <span>Intellectual Property Ownership</span>
          </strong>
        </p>
        <p>
          The Company alone (and its licensors, where applicable) shall own all
          right, title and interest, including all related intellectual property
          rights, in and to the Website, Application and the Host Spaces and any
          suggestions, ideas, enhancement requests, feedback, recommendations or
          other information provided by you or any other party relating to the
          Website or Application (collectively, “Feedback”). You are not
          required to provide any Feedback to the Company. To the extent you do
          provide any Feedback to the Company, you agree to assign and hereby do
          assign to the Company all right, title and interest in and to such
          Feedback and agree that the Company may freely utilize such Feedback
          without compensation to you. This Agreement is not a sale and does not
          convey to you any rights of ownership in or related to the Website or
          Application, or any intellectual property rights owned by the Company.
          The Company name, the Company logo, and the product names associated
          with the Website, Application and Host Spaces are trademarks of the
          Company or third parties, and no right or license is granted to use
          them.
        </p>
        <p>
          <strong>
            <span>Third Party Interactions</span>
          </strong>
        </p>
        <p>
          During use of the Website, Application and Host Spaces, you may enter
          into correspondence with, purchase goods and/or services from, or
          participate in promotions of third party service providers,
          advertisers or sponsors showing their goods and/or services through
          the Website or Application. Any such activity, and any terms,
          conditions, warranties or representations associated with such
          activity, is solely between you and the applicable third party. The
          Company and its licensors shall have no liability, obligation or
          responsibility for any such correspondence, purchase, transaction,
          services or promotion between you and any such third party. The
          Company does not endorse any sites on the Internet that are linked
          through the Website or Application, and in no event shall the Company
          or its licensors be responsible for any content, products, services or
          other materials on or available from such sites or third party
          providers. The Company provides the Website, Application and Host
          Spaces to you pursuant to the terms and conditions of this Agreement.
          You recognize, however, that certain third party providers of goods
          and/or services may require your agreement to additional or different
          terms and conditions prior to your use of or access to such goods or
          services, and the Company disclaims any and all responsibility or
          liability arising from such agreements between you and the third party
          providers.
        </p>
        <p>
          The Company may rely on third party advertising and marketing supplied
          through the Website or Application and other mechanisms to subsidize
          the Website or Application. By agreeing to these terms and conditions
          you agree to receive such advertising and marketing. If you do not
          want to receive such advertising, you must notify us in writing. The
          Company reserves the right to charge you a higher fee for the Website
          or Application, as the case may be, should you choose not to receive
          these advertising services. This higher fee, if applicable, will be
          posted on the Company's website located at{' '}
          <a href='https://www.nappr.io/'>https://www.nappr.io</a>. The Company
          may compile, release and disclose non-nominative information regarding
          you and your use of the Website or Application as part of a customer
          profile or similar report or analysis, including, without limitation
          to third party service providers. You agree that it is your
          responsibility to take reasonable precautions in all actions and
          interactions with any third party you interact with through the Host
          Spaces.
        </p>
        <p>
          <strong>
            <span>Indemnification</span>
          </strong>
        </p>
        <p>
          By entering into this Agreement and using the Website or Application,
          you agree that you shall defend, indemnify and hold the Company, its
          licensors and each of their parent organizations, subsidiaries,
          affiliates, officers, directors, Users, employees, attorneys and
          agents harmless from and against any and all claims, costs, damages,
          losses, liabilities and expenses (including attorneys' fees and costs)
          arising out of or in connection with: (a) your violation or breach of
          any term of this Agreement or any applicable law or regulation,
          whether or not referenced herein; (b) your violation of any rights of
          any third party, or (c) your use or misuse of the Website or
          Application, except in each case solely to the extent any of the
          foregoing arises directly from the gross negligence or willful
          misconduct of the Company or a Building Owner/Renter.
        </p>
        <p>
          <strong>
            <span>Disclaimer of Warranties</span>
          </strong>
        </p>
        <p>
          IF YOU CHOOSE TO USE THE SITE, APPLICATION, SERVICES OR COLLECTIVE
          CONTENT OR PARTICIPATE IN THE REFERRAL PROGRAM, YOU DO SO AT YOUR SOLE
          RISK. YOU ACKNOWLEDGE AND AGREE THAT NAPPR DOES NOT HAVE AN OBLIGATION
          TO CONDUCT BACKGROUND OR REGISTERED SEX OFFENDER CHECKS ON ANY MEMBER,
          INCLUDING, BUT NOT LIMITED TO, GUESTS AND HOSTS, BUT MAY CONDUCT SUCH
          BACKGROUND OR REGISTERED SEX OFFENDER CHECKS IN ITS SOLE DISCRETION.
          IF WE CHOOSE TO CONDUCT SUCH CHECKS, TO THE EXTENT PERMITTED BY
          APPLICABLE LAW, WE DISCLAIM WARRANTIES OF ANY KIND, EITHER EXPRESS OR
          IMPLIED, THAT SUCH CHECKS WILL IDENTIFY PRIOR MISCONDUCT BY A USER OR
          GUARANTEE THAT A USER WILL NOT ENGAGE IN MISCONDUCT IN THE FUTURE.
        </p>
        <p>
          THE SITE, APPLICATION, SERVICES, COLLECTIVE CONTENT AND REFERRAL
          PROGRAM ARE PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EITHER
          EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, NAPPR EXPLICITLY
          DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES
          ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. NAPPR MAKES NO
          WARRANTY THAT THE SITE, APPLICATION, SERVICES, COLLECTIVE CONTENT,
          INCLUDING, BUT NOT LIMITED TO, THE LISTINGS OR ANY ACCOMMODATIONS, OR
          THE REFERRAL PROGRAM WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN
          UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. NAPPR MAKES NO WARRANTY
          REGARDING THE QUALITY OF ANY LISTINGS, ACCOMMODATIONS, HOSTS, GUESTS,
          YOUR ACCRUAL OF NAPPR TRAVEL CREDITS, THE SERVICES OR COLLECTIVE
          CONTENT OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR
          RELIABILITY OF ANY COLLECTIVE CONTENT OBTAINED THROUGH THE SITE,
          APPLICATION, SERVICES OR REFERRAL PROGRAM.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM NAPPR
          OR THROUGH THE SITE, APPLICATION, SERVICES OR COLLECTIVE CONTENT, WILL
          CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
        </p>
        <p>
          YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
          INTERACTIONS WITH OTHER USERS OF THE SITE, APPLICATION OR SERVICES AND
          WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT
          OF YOUR USE OF THE SITE, APPLICATION OR SERVICES, INCLUDING, BUT NOT
          LIMITED TO, ANY HOSTS OR GUESTS. YOU UNDERSTAND THAT NAPPR DOES NOT
          MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF THE SITE,
          APPLICATION OR SERVICES OR TO REVIEW OR VISIT ANY ACCOMMODATIONS.
          NAPPR MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF
          USERS OF THE SITE, APPLICATION OR SERVICES OR THEIR COMPATIBILITY WITH
          ANY CURRENT OR FUTURE USERS OF THE SITE, APPLICATION OR SERVICES. YOU
          AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND
          INTERACTIONS WITH OTHER USERS OF THE SITE, APPLICATION OR SERVICES AND
          WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT
          OF YOUR USE OF THE SITE, APPLICATION OR SERVICES, INCLUDING, BUT NOT
          LIMITED TO, GUESTS AND HOSTS, PARTICULARLY IF YOU DECIDE TO MEET
          OFFLINE OR IN PERSON REGARDLESS OF WHETHER SUCH MEETINGS ARE ORGANIZED
          BY NAPPR. NAPPR EXPLICITLY DISCLAIMS ALL LIABILITY FOR ANY ACT OR
          OMISSION OF ANY GUEST OR OTHER THIRD PARTY.
        </p>
        <p>
          <strong>
            <span>Internet Delays</span>
          </strong>
        </p>
        <p>
          THE WEBSITE AND APPLICATION MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND
          OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC
          COMMUNICATIONS. THE COMPANY IS NOT RESPONSIBLE FOR ANY DELAYS,
          DELIVERY FAILURES, OR OTHER DAMAGES OF WHATSOEVER NATURE RESULTING
          FROM SUCH PROBLEMS.
        </p>
        <p>
          <strong>
            <span>Limitation of Liability</span>
          </strong>
        </p>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
          LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
          SITE, APPLICATION, SERVICES AND COLLECTIVE CONTENT, YOUR LISTING OR
          BOOKING OF ANY ACCOMMODATIONS VIA THE SITE, APPLICATION AND SERVICES,
          YOUR PARTICIPATION IN THE REFERRAL PROGRAM, AND ANY CONTACT YOU HAVE
          WITH OTHER USERS OF NAPPR WHETHER IN PERSON OR ONLINE REMAINS WITH
          YOU. NEITHER NAPPR NOR ANY OTHER PARTY INVOLVED IN CREATING,
          PRODUCING, OR DELIVERING THE SITE, APPLICATION, SERVICES, COLLECTIVE
          CONTENT OR THE REFERRAL PROGRAM WILL BE LIABLE FOR ANY INCIDENTAL,
          SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
          LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER
          DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR
          SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR
          EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS,
          FROM THE USE OF OR INABILITY TO USE THE SITE, APPLICATION, SERVICES OR
          COLLECTIVE CONTENT, FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS
          WITH OTHER USERS OF THE SITE, APPLICATION, OR SERVICES OR OTHER
          PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE
          OF THE SITE, APPLICATION, SERVICES, OR YOUR PARTICIPATION IN THE
          REFERRAL PROGRAM OR FROM YOUR LISTING OR BOOKING OF ANY ACCOMMODATION
          VIA THE SITE, APPLICATION AND SERVICES, WHETHER BASED ON WARRANTY,
          CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER
          LEGAL THEORY, AND WHETHER OR NOT NAPPR HAS BEEN INFORMED OF THE
          POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN
          IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
        </p>
        <p>
          EXCEPT FOR OUR OBLIGATIONS TO PAY AMOUNTS TO APPLICABLE HOSTS PURSUANT
          TO THESE TERMS OR AN APPROVED PAYMENT REQUEST UNDER THE NAPPR HOST
          GUARANTEE, IN NO EVENT WILL NAPPR'S AGGREGATE LIABILITY ARISING OUT OF
          OR IN CONNECTION WITH THESE TERMS AND YOUR USE OF THE SITE,
          APPLICATION AND SERVICES INCLUDING, BUT NOT LIMITED TO, FROM YOUR
          LISTING OR BOOKING OF ANY ACCOMMODATION VIA THE SITE, APPLICATION AND
          SERVICES, OR FROM THE USE OF OR INABILITY TO USE THE SITE,
          APPLICATION, SERVICES, OR COLLECTIVE CONTENT OR YOUR PARTICIPATION IN
          THE REFERRAL PROGRAM AND IN CONNECTION WITH ANY ACCOMMODATION OR
          INTERACTIONS WITH ANY OTHER MEMBERS, EXCEED THE AMOUNTS YOU HAVE PAID
          OR OWE FOR BOOKINGS VIA THE SITE, APPLICATION AND SERVICES AS A GUEST
          IN THE TWELVE (12) MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO THE
          LIABILITY, OR IF YOU ARE A HOST, THE AMOUNTS PAID BY NAPPR TO YOU IN
          THE TWELVE (12) MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO THE
          LIABILITY, OR ONE HUNDRED U.S. DOLLARS (US$100), IF NO SUCH PAYMENTS
          HAVE BEEN MADE, AS APPLICABLE. THE LIMITATIONS OF DAMAGES SET FORTH
          ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
          NAPPR AND YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO
          THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </p>
        <p>
          <strong>
            <span>Rating System</span>
          </strong>
        </p>
        <p>
          You may be required to rate certain aspects of your overall experience
          of the Host Spaces and with your Guests, which will be prompted
          through the Website or Application. If you do not participate in such
          rating when requested, the Company reserves the right (without
          limiting any of Company's other rights hereunder) to restrict your
          access to the Host Spaces.
        </p>
        <p>
          Additionally, if the Company, or if other Users, rate your usage of
          the Host Spaces as being below a threshold acceptable to the Company,
          in its sole discretion, then (without limiting any of Company's other
          rights hereunder) the Company may restrict your access to the Host
          Spaces.
        </p>
        <p>
          <strong>
            <span>Notice</span>
          </strong>
        </p>
        <p>
          The Company may give notice by means of email to your email address on
          record in the Company's account information, or by written
          communication sent by first class mail or pre-paid post to your
          address on record in the Company's account information. Such notice
          shall be deemed to have been given upon the expiration of 48 hours
          after mailing (if sent by first class mail or pre-paid post) or 12
          hours after sending (if sent by email). You may give notice, and
          address any complaint or claim to the Company (such notice, complaint
          or claim shall be deemed given when received by the Company) at any
          time by means of email to{' '}
          <a href='mailto:info@nappr.io'>info@nappr.io</a>.
        </p>
        <p>
          <strong>
            <span>Assignment</span>
          </strong>
        </p>
        <p>
          This Agreement may not be assigned by you (whether in whole or in
          part) without the prior written approval of the Company. This
          Agreement may be assigned without your consent (in whole or in part)
          by the Company, including to (i) a parent or subsidiary, (ii) an
          acquirer of assets, or (iii) a successor by merger. Any purported
          assignment in violation of this section shall be void.
        </p>
        <p>
          <strong>
            <span>Controlling Law and Jurisdiction – Canadian Users</span>
          </strong>
        </p>
        <p>
          If you are a resident of Canada, this Section 20 applies to you. If
          you are a resident of the U.S.A., Section 21 applies to you instead of
          this Section 20. If you are a resident of the United Kingdom, Section
          22 applies to you instead of this Section 20.
        </p>
        <p>
          General - This Agreement and any action related thereto will be
          governed by the laws of the Province of Quebec without regard to its
          conflict of laws provisions. Subject to the rest of this Section 20,
          the exclusive jurisdiction and venue of any action in relation to this
          Agreement will be the District of Montreal and each of the parties
          hereto waives any objection to jurisdiction and venue in such courts.
          However, in the event of the actual or threatened infringement,
          misappropriation or violation of Company's copyrights, trademarks,
          trade secrets, patents or other intellectual property rights, Company
          may, at its discretion, institute legal proceedings in any
          jurisdiction (s) which is (are) deemed necessary or advisable.
        </p>
        <p>
          Arbitration - You and Company agree that any dispute, claim or
          controversy arising out of or relating to this Agreement or the
          breach, termination, enforcement, interpretation or validity thereof
          or the use of the Website or Application (collectively, "Disputes")
          will be settled by binding arbitration, except that each party retains
          the right to bring an individual action in small claims court and the
          right to seek injunctive or other equitable relief in a court of
          competent jurisdiction to prevent the actual or threatened
          infringement, misappropriation or violation of a party's copyrights,
          trademarks, trade secrets, patents or other intellectual property
          rights. You acknowledge and agree that you are waiving the right to
          participate as a plaintiff in any purported class action or
          representative proceeding. Further, unless both you and Company
          otherwise agree in writing, the arbitrators may not consolidate more
          than one person's claims, and may not otherwise preside over any form
          of any class or representative proceeding. If this specific paragraph
          is held unenforceable, then the entirety of this "Dispute Resolution"
          section will be deemed void. Except as provided in the preceding
          sentence, this "Dispute Resolution" section will survive any
          termination of this Agreement.
        </p>
        <p>
          Arbitration Rules - The arbitration will be subject to article 940 and
          following of the <em>Code of civil Procedure</em> (Quebec).
        </p>
        <p>
          Decision - The arbitrators will render an award within the time frame
          specified in the Code of civil Procedure (Quebec). Such decision will
          include the essential findings and conclusions upon which the
          arbitrators based the award. Judgment on the arbitration award may be
          entered in any court having jurisdiction thereof. Any damages granted
          by the arbitrators must be consistent with the terms of the
          "Limitation of Liability" section above as to the types and the
          amounts of damages for which a party may be held liable.
        </p>
        <p>
          Fees - The arbitrators shall determine who is responsible to pay the
          fees associated with the arbitration.
        </p>
        <p>
          <strong>
            <span>Controlling Law and Jurisdiction – United states Users</span>
          </strong>
        </p>
        <p>
          If you are a resident of the United States of America, this Section 21
          applies to you. If you are a resident of Canada, Section 20 applies to
          you instead of this Section 21. If you are a resident of the United
          Kingdom, Section 22 applies to you instead of this Section 21.
        </p>
        <p>
          General - This Agreement and any action related thereto will be
          governed by the laws of the state of New York without regard to its
          conflict of laws provisions. Subject to the rest of this Section 21,
          the exclusive jurisdiction and venue of any action in relation to this
          Agreement will be the state and federal courts located in the Southern
          District of New York and each of the parties hereto waives any
          objection to jurisdiction and venue in such courts. However, in the
          event of the actual or threatened infringement, misappropriation or
          violation of Company's copyrights, trademarks, trade secrets, patents
          or other intellectual property rights, Company may, at its discretion,
          institute legal proceedings in any jurisdiction (s) which is (are)
          deemed necessary or advisable.
        </p>
        <p>
          Arbitration - Any claim (excluding claims for injunctive or other
          equitable relief) where the total amount of the award sought by either
          party is less than $10,000 shall be resolved via binding
          non-appearance-based arbitration initiated through the American
          Arbitration Association (“AAA”). The AAA Rules are available online at{' '}
          <a href='http://www.adr.org/'>http://www.adr.org</a> or by calling the
          AAA at 1-800-778-7879. In any such arbitration, the parties and AAA
          must comply with the following rules: (a) the arbitration shall be
          conducted by telephone, online and/or be solely based on written
          submissions, the specific manner shall be chosen by the party
          initiating the arbitration; (b) the arbitration shall not involve any
          personal appearance by the parties or witnesses unless otherwise
          mutually agreed by the parties; (c) the arbitrator may award
          injunctive or declaratory relief only in favor of the individual party
          seeking relief and only to the extent necessary to provide relief
          warranted by that party's individual claim; and (d) any judgment on
          the award rendered by the arbitrator may be entered in any court of
          competent jurisdiction. Nothing in this Agreement shall prevent either
          party from seeking remedies in small claims court of competent
          jurisdiction.
        </p>
        <p>
          Class Action Waiver - YOU AGREE THAT ANY CLAIMS SUBJECT TO ARBITRATION
          UNDER THIS SECTION 21 MUST BE MADE IN YOUR INDIVIDUAL CAPACITY, AND
          NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
          REPRESENTATIVE PROCEEDING.
        </p>
        <p>
          Time Limits - YOU AND THE COMPANY AGREE THAT ANY CAUSE OF ACTION
          ARISING OUT OF OR RELATED TO THIS AGREEMENT, THE WEBSITE OR
          APPLICATION MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
          ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
        </p>
        <p>
          <strong>
            <span>Controlling Law and Jurisdiction – English Users</span>
          </strong>
        </p>
        <p>
          If you are a resident of England and Wales, this Section 22 applies to
          you. If you are a resident of Canada Section 20 applies to you and if
          you are a resident of the U.S.A., Section 21 applies to you.
        </p>
        <p>
          General - This Agreement and any action related thereto will be
          governed by the laws of England and Wales. Subject to the rest of this
          Section 22, the exclusive jurisdiction and venue of any action in
          relation to this Agreement will be the courts of England and Wales,
          save where you are a consumer and you live in a part of the UK other
          than England and Wales, the applicable law of that part of the UK will
          govern and any Dispute will only be dealt with by the courts there. In
          the event of the actual or threatened infringement, misappropriation
          or violation of Company's copyrights, trademarks, trade secrets,
          patents or other intellectual property rights, Company may, at its
          discretion, institute legal proceedings in any jurisdiction which is
          deemed necessary or advisable.
        </p>
        <p>
          Arbitration - You and Company agree that any dispute, claim or
          controversy arising out of or relating to this Agreement or the
          breach, termination, enforcement, interpretation or validity thereof
          or the use of the Website or Application (collectively, "Disputes")
          will be referred to and finally resolved by arbitration under the
          rules of the London Court of Arbitration (LCIA), which rules are
          deemed incorporated by reference to this clause. The number of
          arbitrators shall be, unless determined by the LCIA otherwise, one and
          the seat of arbitration shall be London, England. The language use in
          the proceedings shall be English. If you are a consumer, you may in
          your discretion decide whether to resolve any Dispute by arbitration
          or whether to exercise any other legal remedy which may be available
          to you.
        </p>
        <p>
          <strong>
            <span>Export Control</span>
          </strong>
        </p>
        <p>
          By using the Website or Application, you represent and warrant that:
          (i) you are not located in a country that is subject to a U.S.
          Government embargo, or that has been designated by the U.S. Government
          as a "terrorist supporting" country; and (ii) you are not listed on
          any U.S. Government list of prohibited or restricted parties.
        </p>
        <p>
          <strong>
            <span>Termination</span>
          </strong>
        </p>
        <p>
          You agree that the Company, in its sole discretion and for any or no
          reason, may terminate any member or customer account (or any part
          thereof) you may have with the Company or your use of Host Space, and
          remove and discard all or any part of your account or any of your User
          Content, at any time. The Company may also in its sole discretion and
          at any time discontinue providing access to the Website, Application
          and Host Spaces, or any part thereof, with or without notice. You
          agree that any termination of your access to the Website, Host Spaces,
          Application or any account you may have or portion thereof may be
          effected without prior notice, and you agree that the Company shall
          not be liable to you. These remedies are in addition to any other
          remedies the Company may have at law or in equity.
        </p>
        <p>
          You may terminate this Agreement at any time by deleting the
          Application from your device, ceasing all use of the Website and Host
          Spaces and requesting the Company to cancel your account via email
          sent to <a href='mailto:info@nappr.io'>info@nappr.io</a>.
        </p>
        <p>
          <strong>
            <span>General</span>
          </strong>
        </p>
        <p>
          No joint venture, partnership, employment, or agency relationship
          exists between you, the Company or any third party provider as a
          result of this Agreement or use of the Website or Application. If any
          provision of the Agreement is held to be invalid or unenforceable,
          such provision shall be struck and the remaining provisions shall be
          enforced to the fullest extent under law. The failure of the Company
          to enforce any right or provision in this Agreement shall not
          constitute a waiver of such right or provision unless acknowledged and
          agreed to by the Company in writing. This Agreement comprises the
          entire agreement between you and the Company and supersedes all prior
          or contemporaneous negotiations, discussions or agreements, whether
          written or oral, between the parties regarding the subject matter
          contained herein. Nothing in this Agreement allows any party who is
          not a party to this Agreement to have any rights under this Agreement
          nor be able to enforce this Agreement.
        </p>
        <br />
        <p>
          <strong>
            You hereby agree to the terms and conditions contained in the
            present Agreement.
          </strong>
        </p>
      </div>
    </div>
  );
};
