import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  MailOutlined,
  UserAddOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Input, Button, notification } from 'antd';
import * as Yup from 'yup';
import axiosInstance from '../../../utils/axios';
import { user } from '../../../store/user';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export const ProfileData = () => {
  const setUser = useSetRecoilState(user);
  const userData = useRecoilValue(user);
  const [buttonLoading, setbuttonLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: userData.email,
      firstname: userData.firstname,
      lastname: userData.lastname,
      phone: userData.phone,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      phone: Yup.string().required('Phone number is required'),
    }),
    onSubmit: (values) => {
      setbuttonLoading(true);
      // Handle form submission
      axiosInstance.post(`api/v1/user`, values).then((res) => {
        if (res?.data?.results) {
          localStorage.setItem(
            'user',
            JSON.stringify({ ...userData, ...res.data.results })
          );
          setUser({ ...userData, ...res.data.results });
          notification.success({
            message: 'User updated successfully',
          });
        }

        setbuttonLoading(false);
      });
    },
  });

  return (
    <div>
      <h3>Update your data</h3>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <Input
            size="large"
            prefix={<MailOutlined />}
            type="email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.email && formik.errors.email && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.email}
            </div>
          )}
        </div>

        <br />
        <div>
          <label htmlFor="firstname">First name:</label>
          <Input
            size="large"
            prefix={<UserAddOutlined />}
            type="firstname"
            id="firstname"
            name="firstname"
            value={formik.values.firstname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.firstname && formik.errors.firstname && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.firstname}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor="lastname">Last name:</label>
          <Input
            size="large"
            prefix={<UserAddOutlined />}
            type="lastname"
            id="lastname"
            name="lastname"
            value={formik.values.lastname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.lastname && formik.errors.lastname && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.lastname}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor="phone">Phone:</label>
          <Input
            size="large"
            prefix={<PhoneOutlined />}
            type="phone"
            id="phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.phone && formik.errors.phone && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.phone}
            </div>
          )}
        </div>
        <br />
        <Button type="primary" loading={buttonLoading} htmlType="submit">
          Update
        </Button>
      </form>
    </div>
  );
};
