import React, { useEffect, useState } from 'react';
import { Button } from '../../../../Components/Button/Button';
import { useParams } from 'react-router-dom';
import { getLatLng } from 'react-places-autocomplete';

import './Search.css';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { GooglePlacesAutocomplete } from '../../../../Components/GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import { useRecoilValue } from 'recoil';
import { userLocation } from '../../../../store/user';
import TiktokPixel from 'tiktok-pixel';

export const Search = ({ geoLocation }) => {
  const params = useParams();

  const locationUser = useRecoilValue(userLocation);
  const [textLocation, setTextLocation] = useState(
    geoLocation || params.geoname || ''
  );
  const [cc, setCc] = useState('');
  const [startDate, setStartDate] = useState(
    params.date ? dayjs(params.date) : dayjs()
  );
  const [location, setLocation] = useState(
    params.lat
      ? {
          lat: parseFloat(params.lat),
          lng: parseFloat(params.lng),
        }
      : null
  );

  useEffect(() => {
    if (locationUser?.lat && !params.lat) {
      setLocation({
        lat: parseFloat(locationUser.lat),
        lng: parseFloat(locationUser.lng),
      });

      setCc(locationUser.cc);
      setTextLocation('Near me');
    }
  }, [locationUser]);

  const [queryDate, setQueryDate] = useState(
    params.date
      ? dayjs(params.date).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
  );
  const navigate = useNavigate();

  const getBounds = async (address, isNear) => {
    if (isNear) {
      setCc(locationUser.cc);
      return;
    }

    for (var i = 0; i < address.address_components.length; i += 1) {
      var addressObj = address.address_components[i];
      for (var j = 0; j < addressObj.types.length; j += 1) {
        if (addressObj.types[j] === 'country') {
          setCc(addressObj.short_name.toLowerCase());
        }
      }
    }

    const latLng = await getLatLng(address);
    setTextLocation(address.formatted_address);
    setLocation(latLng);
  };

  return (
    <div className="search-section">
      <div className="input" style={{ position: 'relative' }}>
        <div className="title">Where to?</div>
        <GooglePlacesAutocomplete
          getBounds={getBounds}
          add={geoLocation || textLocation}
          locationUser={locationUser}
        />
      </div>

      <div className="vl" />

      <div className="input">
        <div className="title" style={{ marginBottom: '0', marginTop: '7px' }}>
          When?
        </div>
        <DatePicker
          disabledDate={(current) => {
            let customDate = dayjs().format('YYYY-MM-DD');
            return current && current < dayjs(customDate, 'YYYY-MM-DD');
          }}
          inputReadOnly={true}
          format={'MMMM D'}
          suffixIcon={false}
          bordered={false}
          defaultPickerValue={startDate}
          defaultValue={startDate}
          placeholder="Pick a day"
          onChange={(date, dateString) => {
            setStartDate(date);
            setQueryDate(dayjs(date).format('YYYY-MM-DD'));
          }}
        />
      </div>

      <Button
        disabled={!location}
        title="Search"
        type="primary"
        onClick={() => {
          TiktokPixel.track('Search', {
            content_name: 'Search',
            content_type: 'product_group',
            timestamp: new Date(),
            query: textLocation,
          });
          navigate(
            `/hotels/${location.lat}/${
              location.lng
            }/${queryDate}/${textLocation}/${cc || params.cc}`
          );
        }}
      />
    </div>
  );
};
