import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './index.css';
import { Landing } from './Pages/Landing/Landing';
import { Hotels } from './Pages/Hotels/Hotels';
import { Hotel } from './Pages/Hotel/Hotel';
import { Nav } from './Components/Nav/Nav';
import { Footer } from './Components/Footer/Footer';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { user, userLocation } from './store/user';
import { ProtectedRoute } from './Components/ProtectedRoute/ProtectedRoute';
import { Bookings } from './Pages/Bookings/Bookings';
import { Privacy } from './Pages/Privacy/Privacy';
import { Terms } from './Pages/Terms/Terms';
import { Faq } from './Pages/Faq/Faq';
import { Contest } from './Pages/Contest/Contest';
import { Profile } from './Pages/Profile/Profile';
import { ForgotPassword } from './Pages/ForgotPassword/ForgotPassword';
import { ConfirmEmail } from './Pages/ConfirmEmail/ConfirmEmail';
import { Unsubscribe } from './Pages/Unsubscribe/Unsubscribe';
import { Press } from './Pages/Press/Press';
import { Team } from './Pages/Team/Team';
import { ContactUs } from './Pages/ContactUs/ContactUs';
import { BookingPolicy } from './Pages/BookingPolicy/BookingPolicy';
import { CancelBooking } from './Pages/CancelBooking/CancelBooking';
import { getUserLocations } from './utils/helpers';
import { useIsMobile } from './hooks/IsMobile';
import { HotelsMobile } from './Pages/HotelsMobile/HotelsMobile';
import { DeletePage } from './Pages/Delete/Delete';
import { QR } from './Pages/QR/QR';
import { Sitemap } from './Pages/Sitemap/Sitemap';
import { Blog } from './Pages/Blog/Blog';
import { BlogContent } from './Pages/BlogContent/BlogContent';
import { PageNotFound } from './404Page/404Page';

function App() {
  TiktokPixel.init('CJRO213C77U5E7966T8G');
  const location = useLocation();
  const setUser = useSetRecoilState(user);
  const userData = useRecoilState(user);
  const setUserLocation = useSetRecoilState(userLocation);
  const isMobile = useIsMobile();

  useEffect(() => {
    const localUserData = localStorage.getItem('user');

    if (localUserData) {
      setUser(JSON.parse(localUserData));
    }

    getUserLocation();
  }, []);

  const getUserLocation = () => {
    // Check if the Geolocation API is available in the browser
    if ('geolocation' in navigator) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // Extract the latitude and longitude from the position object
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const geocoder = new window.google.maps.Geocoder();
          geocoder
            .geocode({ location: { lat: latitude, lng: longitude } })
            .then((response) => {
              if (response.results[0]) {
                const userLocation = getUserLocations(response.results);

                setUserLocation({
                  lat: latitude,
                  lng: longitude,
                  cc: userLocation,
                });
              } else {
                console.log('No results found');
              }
            })
            .catch((e) => console.log('Geocoder failed due to: ' + e));
        },
        (error) => {
          setUserLocation(error);
        }
      );
    } else {
      // Geolocation is not available in this browser
      setUserLocation({
        code: 55,
        message: 'Geolocation is not supported in this browser.',
      });
    }
  };

  return (
    <>
      {isMobile && location.pathname.includes('apps') ? null : <Nav />}

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/hotels" element={<Hotels />} />
        <Route
          path="/hotels/:lat/:lng/:date/:geoname/:cc"
          element={isMobile ? <HotelsMobile /> : <Hotels />}
        />
        <Route path="/hotel/:id" element={<Hotel />} />
        <Route
          path="/hotel/:id/:date/:lat/:lng/:geoname/:cc"
          element={<Hotel />}
        />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contest" element={<Contest />} />
        <Route path="/apps" element={<QR />} />
        <Route path="/team" element={<Team />} />
        <Route path="/press" element={<Press />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id/:title" element={<BlogContent />} />
        <Route path="/booking-policy" element={<BookingPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route
          path="/booking/cancel/:token/:booking_id"
          element={<CancelBooking />}
        />
        <Route path="/forgot-password/:token" element={<ForgotPassword />} />
        <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
        <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
        <Route
          path="/bookings"
          element={
            <ProtectedRoute user={userData}>
              <Bookings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute user={userData}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/delete-account"
          element={
            <ProtectedRoute user={userData}>
              <DeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <PageNotFound />
          }
        />
      </Routes>

      {(isMobile && location.pathname.includes('hotels')) ||
      (isMobile && location.pathname.includes('apps')) ? null : (
        <Footer />
      )}
    </>
  );
}
export default App;
