import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axios';
import './Bookings.css';
import { Booking } from './components/Booking/Booking';
import { Spinner } from '../../Components/Spinner/Spinner';
import * as moment from 'moment';
import { Search } from '../Landing/components/Search/Search';
import dayjs from 'dayjs';

export const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance.get('api/v1/hotels/booking/all').then((res) => {
      if (res?.data?.results) {
        setBookings(res.data.results);
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return <Spinner size='large' />;
  }

  return (
    <>
      <div className='hotels-search'>
        <Search />
      </div>
      {!bookings.length ? (
        <div className='no-bookings'>
          <h2>Next time you book with us it will show here</h2>
        </div>
      ) : (
        <div className='bookings-page'>
          <h1>Bookings</h1>
          <div id='NapsBooked'>
            <h3>Upcoming Bookings</h3>
            <>
              {bookings
                .filter(
                  (b) =>
                    moment().diff(
                      moment(
                        `${b.date} ${b.checkout_time}`,
                        'YYYY-MM-DD HH:mm:ss'
                      ),
                      'hours'
                    ) <= 0
                )
                .filter((b) => !b.canceled)
                .map((b) => {
                  return <Booking booking={b} />;
                })}
            </>
          </div>

          <div>
            <h3>Past Bookings</h3>
            <>
              {bookings
                .filter(
                  (b) =>
                    moment().diff(
                      moment(
                        `${b.date} ${b.checkout_time}`,
                        'YYYY-MM-DD HH:mm:ss'
                      ),
                      'hours'
                    ) > 0 || b.canceled
                )
                .map((b) => {
                  return <Booking booking={b} past />;
                })}
            </>
          </div>
        </div>
      )}
    </>
  );
};
