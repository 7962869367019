import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIsMobile } from '../../../../hooks/IsMobile';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './PopularCities.css';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { cities } from '../../../../utils/constants';

export default function PopularCities() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const formatedDate = dayjs().format('YYYY-MM-DD');
  return (
    <>
      <Swiper
        pagination
        grabCursor
        slidesPerView={isMobile ? 1 : 4}
        spaceBetween={30}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide
          onClick={() => {
            navigate(
              `/hotels/${cities.nyc.gps.lat}/${cities.nyc.gps.lng}/${formatedDate}/${cities.nyc.name}/${cities.nyc.cc}`
            );
          }}
        >
          <div className="city-name">New York</div>
          <img src={require('../../../../assets/cities/nyc.jpg')} />
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            navigate(
              `/hotels/${cities.london.gps.lat}/${cities.london.gps.lng}/${formatedDate}/${cities.london.name}/${cities.london.cc}`
            );
          }}
        >
          <div className="city-name">London</div>
          <img src={require('../../../../assets/cities/london.jpg')} />
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            navigate(
              `/hotels/${cities.los_angeles.gps.lat}/${cities.los_angeles.gps.lng}/${formatedDate}/${cities.los_angeles.name}/${cities.los_angeles.cc}`
            );
          }}
        >
          <div className="city-name">Los Angeles</div>
          <img src={require('../../../../assets/cities/la.jpg')} />
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            navigate(
              `/hotels/${cities.miami.gps.lat}/${cities.miami.gps.lng}/${formatedDate}/${cities.miami.name}/${cities.miami.cc}`
            );
          }}
        >
          <div className="city-name">Miami</div>
          <img src={require('../../../../assets/cities/miami.jpg')} />
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            navigate(
              `/hotels/${cities.montreal.gps.lat}/${cities.montreal.gps.lng}/${formatedDate}/${cities.montreal.name}/${cities.montreal.cc}`
            );
          }}
        >
          <div className="city-name">Montreal</div>
          <img src={require('../../../../assets/cities/mra.jpg')} />
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            navigate(
              `/hotels/${cities.san_francisco.gps.lat}/${cities.san_francisco.gps.lng}/${formatedDate}/${cities.san_francisco.name}/${cities.san_francisco.cc}`
            );
          }}
        >
          <div className="city-name">San Francisco </div>
          <img src={require('../../../../assets/cities/sf.jpg')} />
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            navigate(
              `/hotels/${cities.washington.gps.lat}/${cities.washington.gps.lng}/${formatedDate}/${cities.washington.name}/${cities.washington.cc}`
            );
          }}
        >
          <div className="city-name">Washington D.C.</div>
          <img src={require('../../../../assets/cities/wdc.jpg')} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
