import React from 'react';

import './Privacy.css';
import { Search } from '../Landing/components/Search/Search';

export const Privacy = () => {
  return (
    <div className='privacy-page'>
      <div className='hotels-search'>
        <Search />
      </div>

      <div id='privacy-policy ' className='privacy-page-content'>
        <p>
          <strong>
            <span style={{ fontSize: '18.0pt' }}>NAPPR – PRIVACY POLICY</span>
          </strong>
        </p>
        <p>
          Nappr and Forty Winks LLC (hereinafter referred to as "Nappr", "we",
          "us" or "our") operates a platform and community marketplace that
          helps people form offline experiences and relationships directly with
          one another, where they can create, list, discover and book unique
          accommodations around the world for napping, whether through our
          website or our mobile Platform ("Platform").
        </p>
        <p>
          This Privacy Policy is intended to inform you about how we treat
          Personal Information that we process about you. If you do not agree to
          any part of this Privacy Policy, then we cannot provide the Platform
          or Services to you, and you should stop accessing the Platform and
          deactivate your Nappr Account. You can deactivate your Nappr Account
          at any time by sending an email to {' '}
          <a href='mailto:info@nappr.io'>
            info@nappr.io
          </a>{' '}
          with "DEACTIVATE" as the Subject line.
        </p>
        <p>
          By accessing our Platform, you are consenting to the collection and
          the use of your information by Nappr, but only to the extent described
          herein.
        </p>
        <p>
          Nappr may make modifications, deletions and/or additions to this
          Privacy Policy (Changes) at any time. Changes will be effective: (i)
          thirty (30) days after Nappr provides notice of the Changes, whether
          such notice is provided through the Platform user interface, is sent
          to the email address associated with your account or otherwise; or
          (ii) when you opt-in or otherwise expressly agree to the Changes or a
          version of this Privacy Policy incorporating the Changes, whichever
          comes first.
          <br />
        </p>
        <p>
          <strong>
            <span>Protection of Your Information</span>
          </strong>
        </p>
        <p>
          We are accountable for the information in our custody. Therefore, when
          we collect or use your information, we will utilize commercially
          reasonable safeguards to ensure its protection. It should be noted
          that no security procedure is currently 100% effective. Should any
          breach of your Personal Information occur, we will inform you as soon
          as reasonably possible, as required by applicable law.
          <br />
        </p>
        <p>
          <strong>
            <span>Type and Purpose of Collection</span>
          </strong>
        </p>
        <p>
          We collect information at various points in the Platform to facilitate
          its use by our customers. Specifically, two types of information are
          collected:
        </p>
        <p>
          <em>Non-Personal Information:</em> Upon accessing the Platform,
          certain non-personal information will be automatically collected
          without your knowledge or consent, such as your IP address, location
          data and the referring website ("Non-Personal Information"). We use
          Non-Personal Information to examine our traffic and to view how our
          customers use the Platform. This type of information will not allow
          you to be personally identified although we might be able to associate
          it with your account. For example, we use "cookies", which contain
          only certain statistical information. For more information on cookies
          please see the section on it below.
        </p>
        <p>
          <em>Identifying Personal Information:</em> To utilize some portions of
          the Platform, you must first provide personal information that will
          allow you to be identified (Personal Information). This type of
          information will not be collected without your consent. The purposes
          of the collection of Personal Information are the following:
        </p>
        <ul>
          <li>To fulfill your order</li>
          <li>To send you an order confirmation</li>
          <li>To administer your account</li>
          <li>
            To send you push notifications or SMS messages when you are using
            the Platform in relation you use of the services we provide, for
            example, a confirmation on your Host Listing
          </li>
          <li>
            To send you our newsletter provided you have not unsubscribed. You
            may opt out of such newsletter through using the unsubscribe link or
            by contacting us at {' '}
            <a href='mailto:info@nappr.io'>
              info@nappr.io
            </a>
          </li>
          <li>To establish a relationship with you</li>
          <li>
            To facilitate your service with our affiliated service providers
          </li>
          <li>To anticipate and resolve problems with your service</li>
          <li>To understand your needs and desires vis-à-vis the Platform</li>
          <li>
            To update you on changes to our services or products, including new
            promotions provided that you have not unsubscribed. You may opt out
            of such notifications through the unsubscribe link or by contacting
            us at {' '}
            <a href='mailto:info@nappr.io'>
              info@nappr.io
            </a>
            .
          </li>
        </ul>
        <p>
          We expressly acknowledge that we will not use your Personal
          Information for any other purposes without your consent. Further, we
          will only collect Personal Information to the extent necessary for the
          abovementioned purposes. The Personal Information we collect will vary
          depending on how you are using the Platform, but may include, without
          limitation: (i) your name, mailing address, phone number and email
          address; (ii) credit card information and charge history; (iii) unique
          identifiers such as user name, account number and password; (iv)
          preferences information such as product or content interests,
          communication or marketing preferences; (v) device information and
          data; (vi) reservation history and coupon usage history; and (vii)
          Google, Twitter and Facebook identity.
        </p>
        <p>
          Additionally, Personal Information may be used and disclosed to the
          extent Nappr may deem reasonably necessary to enforce the terms of any
          agreement between you and Nappr, or to protect the rights, property or
          safety of any person or entity.
          <br />
        </p>
        <p>
          <strong>
            <span>Right to Examine Information</span>
          </strong>
        </p>
        <p>
          You have the right to examine any of your Personal Information that we
          collect. Should you wish to examine such information, please send us a
          written request to {' '}
          <a href='mailto:info@nappr.io'>
            info@nappr.io
          </a>
          . We reserve the right to charge you a reasonable administrative fee
          to access your information, as permitted by applicable law. In certain
          cases, we may not be able to provide you with access to all of your
          Personal Information (ex: if the information also pertains to the
          Personal Information of another user).
          <br />
          <br />
          <strong>
            <span>Withdrawal of Consent</span>
          </strong>
        </p>
        <p>
          You may withdraw your consent to the collection of Personal
          Information at any time by sending an email to {' '}
          <a href='mailto:info@nappr.io'>
            info@nappr.io
          </a>
          . Upon receiving notice that you have revoked your consent, we will
          stop using your Personal Information within a reasonable time, which
          will vary depending on what information we have collected and for what
          purpose. Please note that we will send you an email confirmation upon
          receipt of your request. Therefore, if you do not receive a
          confirmation email, please contact us again with your request. If you
          do choose to withdraw such consent, your experience on the Platform
          may be diminished, or your ability to choose some of the options on
          the Platform may be limited.
        </p>
        <p>
          <strong>
            <span>Sharing Information</span>
          </strong>
        </p>
        <p>
          We will not sell, rent or disclose to outside parties the information
          we collect and save except that we may share the collected information
          with other parties as follows:
        </p>
        <p>
          <em>Affiliated Service Providers:</em> We have agreements with various
          affiliated service providers to facilitate the functioning of the
          Platform, with whom we may share the information we have collected.
          For example, we may share your credit card information with the credit
          card service provider to process your purchase. All administrative
          service providers that we use are required to have the same level of
          privacy protection as we have, and therefore we expect that your
          information will be handled with the same level of care that we
          employ. Additionally, for example, we may use analytic services (such
          as, without limitation, Mixpanel).
        </p>
        <p>
          <em>Where required by law:</em> We may share the collected information
          where required by law, specifically in response to a demand from
          government authorities where such demand meets the legal requirements.
        </p>
        <p>
          <em>Statistical Analysis:</em> We may share Non-Personal Information
          and aggregated information with third parties, including but not
          limited to for advertising or marketing purposes. No Personal
          Information will be shared in this manner.
        </p>
        <p>
          <em>Transactions:</em> In connection with, or during negotiations of,
          any merger, sale of company assets, financing or acquisition, or in
          any other situation where Personal Information may be disclosed or
          transferred as one of our business assets.
          <br />
          <br />
          <strong>
            <span>External Links</span>
          </strong>
        </p>
        <p>
          The Application contains links and references to other websites. We
          are not responsible for the collection, use and disclosure of
          information, or the privacy practices of such websites, and we
          expressly disclaim any liability relating thereto.
          <br />
          <br />
          <strong>
            <span>International Transfer</span>
          </strong>
        </p>
        <p>
          Both Personal Information and Non-Personal Information you submit via
          the Platform is sent to our group companies and will processed in the
          USA and stored on secure servers located in the USA. Such information
          may also be transferred by us to our offices and third parties
          mentioned in the circumstances above. The countries concerned may not
          have similar data protection laws to your country. Where we transfer
          your information out of your jurisdiction we will take reasonable
          steps to ensure that your privacy rights continue to be protected. By
          submitting your information to the Platform, you agree to this
          storing, processing and transfer.
          <br />
          <br />
          <strong>
            <span>Payment processing</span>
          </strong>
        </p>
        <p>
          Payment details you provide will be encrypted using secure sockets
          layer (SSL) technology before they are submitted over the internet.
          Payments made on the Platform are made through our payment provider,
          PayPal. You will be providing information directly to PayPal which
          operates a secure server to process payment details, encrypting your
          credit/debit card information and authorizing payment. Information
          which you supply to PayPal is not within our control and is subject to
          PayPal's own privacy policy and terms and conditions.
        </p>
        <p>
          <strong>
            <span>Terms of Service</span>
          </strong>
        </p>
        <p>
          This Privacy Policy is incorporated into and forms part of the Terms
          of Service, which outlines the terms and conditions you agree to when
          accessing and using the Platform, and which can be found at <a href='https://nappr.io/terms'>
            nappr.io/terms
          </a>
          .<br />
          <br />
          <strong>
            <span>Persons Under 18</span>
          </strong>
        </p>
        <p>
          The Platform are not marketed toward persons under the age of 18. If
          Nappr discovers that it has inadvertently collected Personal
          Information about individuals under the age of 18, it will promptly
          delete such information.
          <br />
          <br />
          <strong>
            <span>Cookies and similar technologies</span>
          </strong>
        </p>
        <p>
          When you interact with the Platform, we try to make that experience
          simple and meaningful. When you use our Platform, our web server sends
          a cookie to your computer or mobile device (as the case may be).
          Cookies are small pieces of information which are issued to your
          computer or mobile device when you visit a website or access or use a
          mobile application and which store and sometimes track information
          about your use of the Platform. A number of cookies we use last only
          for the duration of your web session or Platform session and expire
          when you close your browser or exit the Platform. Other cookies are
          used to remember you when you return to the Platform and will last for
          longer.
        </p>
        <p>
          Some of the cookies used by the Platform are set by us, and some are
          set by third parties who are delivering services on our behalf.
        </p>
        <p>
          Most web and mobile device browsers automatically accept cookies but,
          if you prefer, you can change your browser to prevent that or to
          notify you each time a cookie is set. You can also learn more about
          cookies by visiting www.allaboutcookies.org which includes additional
          useful information on cookies and how to block cookies using different
          types of browser or mobile device. Please note, however, that by
          blocking or deleting cookies used on the Platform, you may not be able
          to take full advantage of the Platform.
        </p>
        <p>
          The Platform use cookies and similar technologies to cookies to
          collect information about your access and use of the Platform. You can
          also update your privacy settings on your device by setting the "Limit
          Ad Tracking" and Diagnostics and Usage setting property located in the
          settings screen of your Apple iPhone or iPad, or by resetting your
          Android ID through apps that are available in the Play Store. You can
          also stop information collection by uninstalling the Platform on your
          device and you can use the standard uninstall process available as
          part of your device for this purpose.
        </p>
        <p>
          Some information on the types of cookies we use and how we use them is
          set out below:
        </p>
        <p>
          <strong>
            <span style={{ fontSize: '14.0pt' }}>Type of cookies</span>
          </strong>
        </p>
        <p>
          <strong>
            <em>Essential</em>
          </strong>
        </p>
        <p>
          Allow you to navigate and use all the features provided by our
          Platform
        </p>
        <p>
          <strong>
            <em>Functional</em>
          </strong>
        </p>
        <p>
          These cookies customize elements of the promotional layout and/or
          content of the pages of the Platform and remember that you have
          visited us before; this means we can identify the number of unique
          visitors we receive. This allows us to make sure we have enough
          capacity for the number of users that we get. So when you use our
          Platform, we remember you and your preferences and information you
          have submitted to us to provide you with a customized experience. For
          example, remembering your 'home city'.
        </p>
        <p>
          <strong>
            <em>Performance and Analytics</em>
          </strong>
        </p>
        <p>
          Performance cookies let us collect information about how you use the
          Platform. They collect anonymous statistical information about how you
          use the Platform (including how long you spend on the Platform) and
          where you have come to the Platform from, so that we can improve the
          Platform and learn which functions of the Platform are most popular
          with users. This information does not include Personal Information
          although we might be able to associate such information with user
          accounts once we receive it from the third party.
        </p>
        <p>
          <strong>
            <em>Advertising and Targeting</em>
          </strong>
        </p>
        <p>
          These cookies collect information about the pages you visit online.
          They gather information about the pages that you visit or the
          functions of the Platform you use, and also other information about
          other websites that you visit, so as to place you in a "market
          segment". This information is only collected by reference to the IP
          address or unique device identifier that you are using (the applicable
          cookie identifier) and also can include information about the county
          and city you are in, together with the name of your internet service
          provider. This information is then used to place interest-based
          advertisements on the Platform which it is believed will be relevant
          to your market segment. This may result in you seeing adverts for our
          Platform when you visit other websites.
        </p>
        <p>
          For more information about this type of interest-based advertising,
          and about how to turn this feature off please visit{' '}
          <a href='http://www.youronlinechoices.com/uk/'>
            http://www.youronlinechoices.com/uk/
          </a> or follow the link to the website of our advertising partner shown
          below.
        </p>
        <p>
          We also use third parties, for example, Google Analytics, to analyze
          statistical information from users of the Platform. You can understand
          Google Analytics further{' '}
          <a href='https://www.google.com/intl/en/analytics/learn/privacy.html'>
            here
          </a>{' '}
          and learn about how to manage Google Analytics settings{' '}
          <a href='https://tools.google.com/dlpage/gaoptout'>
            here
          </a>
          .<br />
          <br />
          <strong>
            <span>Social Media</span>
          </strong>
        </p>
        <p>
          You can log-on to your account with us by using social media plug-ins
          from social media platforms such as Google and Facebook. If you choose
          to log in via a social media platform, our Platform will connect to
          the servers of the social media platform. We will receive your basic
          profile information as directed by the relevant social media platform
          when you log in. You will also have the option to share features of
          our Platform with the relevant social media platform as you wish.
          Visit the privacy policy of the relevant social media platform
          provider to learn more about your rights and choices.
        </p>
        <p>
          Should you have any questions or comments concerning this Privacy
          Policy, please do not hesitate to contact us at {' '}
          <a href='mailto:info@nappr.io'>
            info@Nappr.io
          </a>
          .
        </p>
      </div>
    </div>
  );
};
