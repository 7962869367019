import dayjs from 'dayjs';

export const getUserLocations = (data) => {
  let cc;
  for (var i = 0; i < data[0].address_components.length; i += 1) {
    var addressObj = data[0].address_components[i];
    for (var j = 0; j < addressObj.types.length; j += 1) {
      if (addressObj.types[j] === 'country') {
        cc = addressObj.short_name.toLowerCase();
      }
    }
  }

  return cc;
};

export const haversineDistance = (lat, lng, searchlat, searchlng) => {
  const R = 6371e3; // Earth's radius in meters
  const φ1 = (lat * Math.PI) / 180;
  const φ2 = (searchlat * Math.PI) / 180;
  const Δφ = ((searchlat - lat) * Math.PI) / 180;
  const Δλ = ((searchlng - lng) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const miles = R * c * 0.000621371;
  return +miles.toFixed(2);
};

function generateTimeArray(startTime, endTime, interval) {
  let times = [];
  let current = dayjs(startTime, 'HH:mm');

  while (
    current.isBefore(dayjs(endTime, 'HH:mm')) ||
    current.isSame(dayjs(endTime, 'HH:mm'))
  ) {
    times.push(current.format('HH:mm'));
    current = current.add(interval, 'minute');
  }

  return times;
}

export const getTimearry = (hotels) => {
  const checkin = [];
  const checkout = [];

  hotels.map((hotel) => {
    if (
      hotel?.least_expensive_product?.check_in_time &&
      hotel?.least_expensive_product?.check_out_time
    ) {
      const checkInOut = getCheckInOutTime(
        hotel?.least_expensive_product,
        hotel?.most_expensive_product
      );
      checkin.push(checkInOut.checking);
      checkout.push(checkInOut.checkout);
    }
  });

  if (checkin.length && checkout.length) {
    const checkinTimes = checkin.map((time) => dayjs(time, 'HH:mm:ss'));
    const minTime = checkinTimes.reduce((min, current) =>
      current.isBefore(min) ? current : min
    );

    const checkoutTimes = checkout.map((time) => dayjs(time, 'HH:mm:ss'));
    const maxTime = checkoutTimes.reduce(
      (max, current) => (current.isAfter(max) ? current : max),
      checkoutTimes[0]
    );

    const timeArray = generateTimeArray(
      minTime.format('HH:mm:ss'),
      maxTime.format('HH:mm'),
      30
    );

    return timeArray;
  }

  return [];
};

export const getCheckInOutTime = (room1, room2) => {
  // Convert room1 times to dayjs objects
  const checkIn1 = dayjs(room1.check_in_time, 'HH:mm:ss');
  const checkOut1 = dayjs(room1.check_out_time, 'HH:mm:ss');

  let earliestCheckIn = room1.check_in_time;
  let latestCheckOut = room1.check_out_time;

  if (room2) {
    // Convert room2 times to dayjs objects
    const checkIn2 = dayjs(room2.check_in_time, 'HH:mm:ss');
    const checkOut2 = dayjs(room2.check_out_time, 'HH:mm:ss');

    // Compare and update the earliest check-in and latest check-out
    if (checkIn2.isBefore(checkIn1)) {
      earliestCheckIn = room2.check_in_time;
    }
    if (checkOut2.isAfter(checkOut1)) {
      latestCheckOut = room2.check_out_time;
    }
  }

  return { checking: earliestCheckIn, checkout: latestCheckOut };
};

export const createTimeArray = (startTime, endTime, intervalMinutes = 60) => {
  let start = dayjs(`2000-01-01 ${startTime}`);
  let end = dayjs(`2000-01-01 ${endTime}`).add(intervalMinutes, 'minute');
  let times = [];

  while (start.isBefore(end)) {
    // Format the time in 24-hour format
    times.push(start.format('HH:mm'));
    start = start.add(intervalMinutes, 'minute');
  }

  return times;
};

export const calculateHoursBetween = (startTime, endTime) => {
  let start = dayjs(`2000-01-01 ${startTime}`);
  let end = dayjs(`2000-01-01 ${endTime}`);

  // Calculate the difference in milliseconds and convert to hours
  let diffInHours = end.diff(start, 'hour', true);

  return diffInHours;
};
