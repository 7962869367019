import React, { useEffect, useState, useRef } from 'react';
import { Slider, Spin, Dropdown, Typography, Space, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './HotelsMobile.css';
import axiosInstance from '../../utils/axios';
import { Spinner } from '../../Components/Spinner/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { currenices } from '../../utils/currency';
import moment from 'moment';
import TiktokPixel from 'tiktok-pixel';
import { useIsMobile } from '../../hooks/IsMobile';
import { SearchMobile } from '../../Components/SearchMobile/SearchMobile';
import { Button } from '../../Components/Button/Button';
import { ArrowDownOutlined } from '@ant-design/icons';
import {
  getCheckInOutTime,
  getTimearry,
  haversineDistance,
} from '../../utils/helpers';
import { Hotel } from '../Hotels/components/Hotel/Hotel';
import Maps from '../Hotels/components/Maps/Maps';
import dayjs from 'dayjs';

export const HotelsMobile = () => {
  const navigate = useNavigate();
  const [hotels, setHotels] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [sortBy, setSortBy] = useState(1);
  const [distance, setDistance] = useState(20);
  const [open, setOpen] = useState(false);
  const [checkin, setCheckin] = useState('');
  const [checkout, setCheckout] = useState('');
  const isMobile = useIsMobile();
  const params = useParams();
  const divRef = useRef(null);

  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Hotels',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  useEffect(() => {
    if (!dayjs().isAfter(params.date, 'days')) {
      const element = document.getElementsByClassName('hotels-hotel')[0];
      element.scrollTop = 0;
      setLoading(true);
      setHotels([]);
      getAllHotels(true);
    } else {
      navigate(
        `/hotels/${params.lat}/${params.lng}/${dayjs().format('YYYY-MM-DD')}/${
          params.geoname
        }/${params.cc}`
      );

      window.location.reload();
    }
  }, [params]);

  const getAllHotels = async (relocate) => {
    const hotelsData = await axiosInstance.get(
      `api/v1/hotels/all/${params.date}/${relocate ? 1 : page}/${params.lat}/${
        params.lng
      }/${params.cc}`
    );
    if (hotelsData?.data?.results) {
      setPage(Number(hotelsData?.data?.results.page) + 1);
      const hotelData =
        page === 1 || relocate
          ? [...hotelsData?.data?.results.hotels]
          : [...hotels, ...hotelsData?.data?.results.hotels];

      const highestRate = getHighestRate(hotelData);

      const lowestRate = getLowestRate(hotelData);

      setPriceRange([lowestRate, highestRate]);
      page === 1 || relocate
        ? setHotels([...hotelsData?.data?.results.hotels])
        : setHotels([...hotels, ...hotelsData?.data?.results.hotels]);

      setLoading(false);
      if (hotels.length) {
        setLoadMore(false);
      }
    }
  };

  const getHighestRate = (data) => {
    let highestRate = 0;

    for (let i = 0; i < data.length; i++) {
      const rate = parseFloat(data[i].most_expensive_product?.total_price);
      if (rate > highestRate) {
        highestRate = rate;
      }
    }

    return highestRate;
  };

  const getLowestRate = (data) => {
    let lowestRates = [];

    for (let i = 0; i < data.length; i++) {
      const rate = parseFloat(data[i].least_expensive_product?.total_price);

      if (rate) {
        lowestRates.push(rate);
      }
    }

    return Math.min(...lowestRates) || 0;
  };

  const filterByRate = () => {
    const filteredData = hotels.filter((hotel) => {
      if (!hotel.distance) {
        hotel.distance = haversineDistance(
          hotel.gps.lat,
          hotel.gps.lon,
          params.lat,
          params.lng
        );
      }
      const rate = parseFloat(hotel.most_expensive_product?.total_price);
      return rate >= priceRange[0] && rate <= priceRange[1];
    });
    return filterByDistance(filteredData);
  };

  const filterByDistance = (filteredRateData) => {
    const filteredData = filteredRateData.filter((hotel) => {
      if (!hotel.distance) {
        hotel.distance = haversineDistance(
          hotel.gps.lat,
          hotel.gps.lon,
          params.lat,
          params.lng
        );
      }
      const hotelDistance = parseFloat(hotel.distance);

      return hotelDistance <= distance;
    });
    return filteredData;
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = divRef.current;

    // Calculate the distance between the bottom of the content and the current scroll position
    const distanceToBottom = scrollHeight - (scrollTop + clientHeight);

    // Set a threshold of 1 pixel to account for small discrepancies
    const threshold = 1;

    if (distanceToBottom <= threshold && !loadMore) {
      // The user has reached the end of the scrollable content
      getAllHotels();
      setLoadMore(true);
    }
  };

  const items = [
    {
      key: '1',
      label: 'Distance',
    },
    {
      key: '2',
      label: 'Price - Low to High',
    },
    {
      key: '3',
      label: 'Price - High to Low',
    },
    {
      key: '4',
      label: (
        <div
          style={{
            marginTop: 20,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 10,
          }}
        >
          <span>
            Price: $ {priceRange[0]} - $ {priceRange[1]}
          </span>
          <Slider
            range
            max={getHighestRate(hotels)}
            defaultValue={priceRange}
            value={priceRange}
            onChange={(e) => setPriceRange(e)}
            tooltip={{
              open: false,
            }}
          />
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div
          style={{
            marginTop: 20,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 10,
          }}
        >
          <span>
            Distance: {0}mi - {distance}mi
          </span>
          <Slider
            max={20}
            defaultValue={distance}
            value={distance}
            onChange={(e) => setDistance(e)}
            tooltip={{
              open: false,
            }}
          />
        </div>
      ),
    },
    {
      key: '6',
      label: (
        <div
          style={{
            marginTop: 20,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 10,
          }}
        >
          <span>Check-in time:</span>
          <Select
            defaultValue={checkin}
            onChange={(val) => {
              setCheckin(val);
              setOpen(false);
            }}
            style={{ display: 'block' }}
            options={[
              { value: '', label: 'Flexible' },

              ...getTimearry(hotels).map((time) => ({
                value: time,
                label: dayjs(time, 'HH:mm').format('h:mm A'),
              })),
            ]}
          />
        </div>
      ),
    },
    {
      key: '7',
      label: (
        <div
          style={{
            marginTop: 20,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 10,
          }}
        >
          <span>Check-out time:</span>
          <Select
            defaultValue={checkout}
            onChange={(val) => {
              setCheckout(val);
              setOpen(false);
            }}
            style={{ display: 'block' }}
            options={[
              { value: '', label: 'Flexible' },

              ...getTimearry(hotels).map((time) => ({
                value: time,
                label: dayjs(time, 'HH:mm').format('h:mm A'),
              })),
            ]}
          />
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div className="scroll-info">Scroll down to see the hotels list</div>
      <div
        className="maps-hotel"
        style={{
          width: '100%',
          height: 'calc(100% - 86px)',
          marginTop: '86px',
        }}
      >
        <Maps
          hotels={filterByRate()
            .filter((h) => h.least_expensive_product?.total_price)
            .filter((h) => {
              const checkInOut = getCheckInOutTime(
                h.least_expensive_product,
                h.most_expensive_product
              );

              if (!checkin && checkout) {
                return (
                  dayjs(checkout, 'HH:mm').format('HH') ==
                  dayjs(checkInOut.checkout, 'HH:mm:ss').format('HH')
                );
              }

              if (checkin) {
                if (checkout) {
                  return (
                    dayjs(checkin, 'HH:mm').format('HH') <=
                      dayjs(checkInOut.checkout, 'HH:mm:ss').format('HH') &&
                    dayjs(checkin, 'HH:mm').format('HH') >=
                      dayjs(checkInOut.checking, 'HH:mm:ss').format('HH') &&
                    dayjs(checkout, 'HH:mm').format('HH') ==
                      dayjs(checkInOut.checkout, 'HH:mm:ss').format('HH')
                  );
                }

                return (
                  dayjs(checkin, 'HH:mm').format('HH') <=
                    dayjs(checkInOut.checkout, 'HH:mm:ss').format('HH') &&
                  dayjs(checkin, 'HH:mm').format('HH') >=
                    dayjs(checkInOut.checking, 'HH:mm:ss').format('HH')
                );
              }

              return h;
            })}
        />
      </div>
      <div className="go-down-map">
        <Button
          onClick={() => {
            const currentScroll = window.scrollY;
            const viewportHeight = window.innerHeight;

            window.scrollTo({
              top: currentScroll + viewportHeight - 210,
              behavior: 'smooth',
            });
          }}
          title={
            loading ? (
              <Spin size="small" style={{ color: 'white' }} />
            ) : (
              <ArrowDownOutlined />
            )
          }
        />
      </div>
      <SearchMobile />

      <div
        className={
          isMobile ? 'hotes-page-content-mobile' : 'hotes-page-content'
        }
      >
        <>
          <div
            ref={divRef}
            className="hotels-hotel"
            style={{
              width: '50%',
              height: '100%',
              overflowY: 'auto',
            }}
            onScroll={handleScroll}
          >
            {loading ? (
              <Spinner size="large" />
            ) : (
              <>
                <div className="sortby-container">
                  <Dropdown
                    open={open}
                    trigger={['click']}
                    onOpenChange={setOpen}
                    menu={{
                      items,
                      selectable: true,
                      defaultSelectedKeys: ['1'],
                      onClick: (e) => {
                        if (
                          Number(e.key) === 1 ||
                          Number(e.key) === 2 ||
                          Number(e.key) === 3
                        ) {
                          setSortBy(Number(e.key));
                        }
                      },
                    }}
                  >
                    <Typography.Link style={{ color: 'black' }}>
                      <Space>
                        Sort and Filter:
                        <DownOutlined />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <h2>{`${
                    filterByRate()
                      .filter((h) => h.least_expensive_product?.total_price)
                      .filter((h) => {
                        const checkInOut = getCheckInOutTime(
                          h.least_expensive_product,
                          h.most_expensive_product
                        );

                        if (!checkin && checkout) {
                          return (
                            dayjs(checkout, 'HH:mm').format('HH') ==
                            dayjs(checkInOut.checkout, 'HH:mm:ss').format('HH')
                          );
                        }

                        if (checkin) {
                          if (checkout) {
                            return (
                              dayjs(checkin, 'HH:mm').format('HH') <=
                                dayjs(checkInOut.checkout, 'HH:mm:ss').format(
                                  'HH'
                                ) &&
                              dayjs(checkin, 'HH:mm').format('HH') >=
                                dayjs(checkInOut.checking, 'HH:mm:ss').format(
                                  'HH'
                                ) &&
                              dayjs(checkout, 'HH:mm').format('HH') ==
                                dayjs(checkInOut.checkout, 'HH:mm:ss').format(
                                  'HH'
                                )
                            );
                          }

                          return (
                            dayjs(checkin, 'HH:mm').format('HH') <=
                              dayjs(checkInOut.checkout, 'HH:mm:ss').format(
                                'HH'
                              ) &&
                            dayjs(checkin, 'HH:mm').format('HH') >=
                              dayjs(checkInOut.checking, 'HH:mm:ss').format(
                                'HH'
                              )
                          );
                        }

                        return h;
                      }).length
                  } hotels found`}</h2>
                </div>

                <div className="hotels-grid">
                  {filterByRate()
                    .filter((h) => h.least_expensive_product?.total_price)
                    .filter((h) => {
                      const checkInOut = getCheckInOutTime(
                        h.least_expensive_product,
                        h.most_expensive_product
                      );

                      if (!checkin && checkout) {
                        return (
                          dayjs(checkout, 'HH:mm').format('HH') ==
                          dayjs(checkInOut.checkout, 'HH:mm:ss').format('HH')
                        );
                      }

                      if (checkin) {
                        if (checkout) {
                          return (
                            dayjs(checkin, 'HH:mm').format('HH') <=
                              dayjs(checkInOut.checkout, 'HH:mm:ss').format(
                                'HH'
                              ) &&
                            dayjs(checkin, 'HH:mm').format('HH') >=
                              dayjs(checkInOut.checking, 'HH:mm:ss').format(
                                'HH'
                              ) &&
                            dayjs(checkout, 'HH:mm').format('HH') ==
                              dayjs(checkInOut.checkout, 'HH:mm:ss').format(
                                'HH'
                              )
                          );
                        }

                        return (
                          dayjs(checkin, 'HH:mm').format('HH') <=
                            dayjs(checkInOut.checkout, 'HH:mm:ss').format(
                              'HH'
                            ) &&
                          dayjs(checkin, 'HH:mm').format('HH') >=
                            dayjs(checkInOut.checking, 'HH:mm:ss').format('HH')
                        );
                      }

                      return h;
                    })
                    .sort((a, b) => {
                      if (!a.distance) {
                        a.distance = haversineDistance(
                          a.gps.lat,
                          a.gps.lon,
                          params.lat,
                          params.lng
                        );
                      }
                      if (!b.distance) {
                        b.distance = haversineDistance(
                          b.gps.lat,
                          b.gps.lon,
                          params.lat,
                          params.lng
                        );
                      }

                      if (sortBy === 1) {
                        return a.distance - b.distance;
                      } else if (sortBy === 2) {
                        var aMinRate = a.least_expensive_product?.total_price;

                        var bMinRate = b.least_expensive_product?.total_price;
                        return aMinRate - bMinRate;
                      } else if (sortBy === 3) {
                        var aMaxRate = a.most_expensive_product?.total_price;
                        var bMaxRate = b.most_expensive_product?.total_price;
                        return bMaxRate - aMaxRate;
                      }

                      return;
                    })
                    .map((hotel) => {
                      const checkInOut = getCheckInOutTime(
                        hotel.least_expensive_product,
                        hotel.most_expensive_product
                      );
                      const time = `${moment(
                        checkInOut.checking,
                        'HH:mm:ss'
                      ).format('h:mm A')} - ${moment(
                        checkInOut.checkout,
                        'HH:mm:ss'
                      ).format('h:mm A')}`;

                      var minRate = hotel.least_expensive_product?.total_price;
                      var maxRate = hotel.most_expensive_product?.total_price;

                      const price =
                        minRate === maxRate
                          ? `${
                              currenices[
                                hotel.least_expensive_product?.currency
                              ].symbol
                            } ${hotel.least_expensive_product?.total_price}`
                          : `${
                              currenices[
                                hotel.least_expensive_product?.currency
                              ].symbol
                            } ${minRate} - ${
                              currenices[
                                hotel.least_expensive_product?.currency
                              ].symbol
                            } ${maxRate}`;
                      const hotelImage = hotel.images?.[0]?.url;
                      return (
                        <Hotel
                          image={hotelImage}
                          id={hotel.hotel_id}
                          key={hotel.id}
                          name={hotel.name}
                          startDate={params.date}
                          address={`${hotel.address}`}
                          cityAndState={`${hotel.city}, ${hotel.state}`}
                          time={time}
                          price={price}
                          distance={hotel.distance}
                        />
                      );
                    })}
                </div>
              </>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px 0',
                height: '50px',
              }}
            >
              {loadMore && <Spin size="small" />}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
