import React from 'react';

import './Quotes.css';

export const Quotes = ({ icon, title, desc }) => {
  return (
    <div className='quotes'>
      <div className='quotes-top'>
        <div className='quotes-icon'>
          <img
            src={require(
                icon === 'hotel'
              ? '../../../../assets/images/homepage/hotel.png'
              : icon === 'money'
              ? '../../../../assets/images/homepage/money.png'
              : icon === 'cities'
              ? '../../../../assets/images/homepage/cities.png'
              : icon === "amenities"
              ? '../../../../assets/images/homepage/amenities.png'
              : icon === "nap"
              ? '../../../../assets/images/homepage/nap.png'
              : icon === "airplane"
              ? '../../../../assets/images/homepage/airplane.png'
              : icon === "relax"
              ? '../../../../assets/images/homepage/relax.png'
              : icon === "student"
              ? '../../../../assets/images/homepage/student.png'
              : icon === "hospital"
              ? '../../../../assets/images/homepage/hospital.png'
              : icon === "drive"
              ? '../../../../assets/images/homepage/road-trip.png'
              : icon === "conference"
              ? '../../../../assets/images/homepage/conference.png'
              : '../../../../assets/images/homepage/alert.png')}
          />
        </div>
        <div className='quotes-title'>{title}<p className='quotes-bottom-p'>{desc}</p></div>
        
      </div>

      <div className='quotes-bottom'>
        <p className='quotes-bottom-p'></p>
      </div>
    </div>
  );
};
