import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockFilled,
  LockOutlined,
} from '@ant-design/icons';
import { Input, Button, notification } from 'antd';
import * as Yup from 'yup';
import axiosInstance from '../../../utils/axios';
import { useSetRecoilState } from 'recoil';
import { user } from '../../../store/user';
import { useNavigate } from 'react-router-dom';

export const ProfilePassword = () => {
  const [buttonLoading, setbuttonLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required('Old Password is a required field.'),
      newPassword: Yup.string().required('New Password is a required field.'),
      repeatPassword: Yup.string()
        .required('Repeat password a is required field.')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      setbuttonLoading(true);
      // Handle form submission
      axiosInstance.post('api/v1/auth/reset-password', values).then((res) => {
        if (res?.data?.results) {
          formik.resetForm();
          notification.success({
            message: 'Password has been changed successfully!',
          });
        }
        setbuttonLoading(false);
      });
    },
  });

  return (
    <div>
      <h3>Update your password</h3>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label htmlFor="oldPassword">Current password:</label>
          <Input.Password
            size="large"
            prefix={<LockFilled />}
            type="oldPassword"
            id="oldPassword"
            name="oldPassword"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {formik.touched.oldPassword && formik.errors.oldPassword && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.oldPassword}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor="newPassword">New password:</label>
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            type="newPassword"
            id="newPassword"
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.newPassword}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor="repeatPassword">Repeat password:</label>
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            type="repeatPassword"
            id="repeatPassword"
            name="repeatPassword"
            value={formik.values.repeatPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {formik.touched.repeatPassword && formik.errors.repeatPassword && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.repeatPassword}
            </div>
          )}
        </div>
        <br />
        <Button type="primary" loading={buttonLoading} htmlType="submit">
          Update Passowrd
        </Button>
      </form>

      <Button
        className="delete-account"
        onClick={() => navigate('/delete-account')}
        type="primary"
        danger
      >
        Delete account
      </Button>
    </div>
  );
};
