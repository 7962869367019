export const defaultGps = {
  gps: { lat: 40.7127753, lng: -74.0059728 },
  name: 'New York, NY, USA',
  cc: 'us',
};

export const cities = {
  nyc: {
    gps: { lat: 40.7127753, lng: -74.0059728 },
    name: 'New York, NY, USA',
    cc: 'us',
  },
  london: {
    gps: { lat: 51.5073509, lng: -0.1277583 },
    name: 'London, UK',
    cc: 'gb',
  },
  los_angeles: {
    gps: { lat: 34.0522342, lng: -118.2436849 },
    name: 'Los Angeles, CA, USA',
    cc: 'us',
  },
  washington: {
    gps: { lat: 38.9071923, lng: -77.0368707 },
    name: 'Washington, DC, USA',
    cc: 'us',
  },
  montreal: {
    gps: { lat: 45.5016889, lng: -73.567256 },
    name: 'Montreal, QC, Canada',
    cc: 'ca',
  },
  miami: {
    gps: { lat: 25.7616798, lng: -80.1917902 },
    name: 'Miami, FL, USA',
    cc: 'us',
  },
  san_francisco: {
    gps: { lat: 37.7749295, lng: -122.4194155 },
    name: 'San Francisco, CA, USA',
    cc: 'us',
  },
};
