import React, { useEffect } from 'react';

import './Unsubscribe.css';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axios';

export const Unsubscribe = () => {
  const params = useParams();

  useEffect(() => {
    axiosInstance.delete(`api/v1/subscribe/${params.token}`);
  }, []);

  return (
    <div className='confirmation-email'>
      <div className='confirmation-email-title'>Unsubscribed</div>
      <br />
      <p>
        We are sorry to see you go. You will no longer recieve emails about our
        best offers and our newsletter. If you miss any of this you are free to
        subscribe again!
      </p>
      <div>
        <br />
        <br />
        <div>Thank you again from the Nappr team.</div>
      </div>
    </div>
  );
};
