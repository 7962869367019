import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Search } from '../Landing/components/Search/Search';

import './BookingPolicy.css';

export const BookingPolicy = () => {
  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Policy',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  return (
    <>
      <div className='hotels-search'>
        <Search />
      </div>
      <div class='booking-policy-page'>
        <h1>
          <a id='top'>Booking Policy | Nappr </a>
        </h1>
        <br />
        <h2>General Booking Policy</h2>
        <ul>
          <li>
            All bookings with Nappr are for check-in and check-out on the same
            day.
          </li>
          <li>
            Check-out times are fixed. Late departures are subject to extra
            fees, at the discretion of the property.
          </li>
          <li>
            You can check-in anytime during the allocated time-band, all the way
            up to the last hour of your stay or the property reserves the right
            to refuse you.
          </li>
          <li>
            Room assignments are made at check-in. You can ask the Front Desk
            for your desired room.
          </li>
          <li>
            You must be of legal adult age to book, or have a notarized parental
            consent. It is the property's choice to honor the booking if you do
            not meet requirements.
          </li>
          <li>
            Name on the booking, along with matching governmental issued photo
            ID &amp; credit card are required at check-in.
          </li>
          <li>
            Frequent Travel Club points, Hilton Honors, or Brand Loyalty points
            do not apply to any Nappr bookings.
          </li>
          <li>
            A temporary hold for incidentals is charged on your credit card at
            check-in. The hold gets removed 5-7 days post check-out.
          </li>
          <li>
            Some properties reserve the right to charge an "Amenities fee" —
            includes use of Wi-Fi &amp; other amenities. Please verify upon
            check-in.
          </li>
        </ul>
        <br />
        <h2>"Book Now, Pay at Property Bookings"</h2>
        <ul>
          <li>
            All payments are made at the property. Some properties accept cash
            payments, please inquire about this at check-in. Gift cards are
            never accepted.
          </li>
          <li>
            Cancellations for "Book now, Pay at Property" bookings are free of
            charge.
          </li>
          <li>
            Cancellations can only be processed via the link above—not by phone,
            chat, nor email.
          </li>
          <li>
            If you do not show up multiple times, a hotel may prevent you from
            booking in the future. Please be courteous and use the cancellation
            link if you foresee that you will not make it.
          </li>
        </ul>
        <br />
        <h2>
          "Book Now with Credit Card" &amp; "Limited, Non-Refundable Bookings"
        </h2>
        <ul>
          <li>
            "Book Now with Card" bookings cancelled 25 hours prior to check-in
            will be refunded in cash or loyalty points (choice is yours).
          </li>
          <li>
            Any cancelation within 25 hours prior to check-in is final and
            cannot be refunded.
          </li>
          <li>
            "Limited, Non-refundable" bookings will be charged immediately upon
            booking and due to limited inventory, cannot be refunded nor
            transferred to a different date.
          </li>
          <li>
            Should your card decline or be invalid, the property reserves the
            right to assign the room to someone else.
          </li>
        </ul>
      </div>
    </>
  );
};
