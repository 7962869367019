import React, { useEffect, useState } from 'react';

export const CustomImage = ({ url }) => {
  const [img, setImg] = useState();

  useEffect(() => {
    setImg(url);
  }, [url]);

  const onError = () => {
    setImg(
      'https://img.freepik.com/free-photo/beautiful-luxury-outdoor-swimming-pool-hotel-resort_74190-7433.jpg'
    );
  };

  if (!url) {
    return null;
  }

  return <img src={img} alt="Nappr" onError={onError} />;
};
