import React, { useState } from 'react';
import { useFormik } from 'formik';
import { MailOutlined } from '@ant-design/icons';

import { Button, Input, notification } from 'antd';
import * as Yup from 'yup';
import axiosInstance from '../../utils/axios';

const ForgotPassword = ({ handleCloseModal }) => {
  const [buttonLoading, setbuttonLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: (values) => {
      setbuttonLoading(true);
      // Handle form submission
      axiosInstance.post('api/v1/auth/forgot-password', values).then((res) => {
        if (res?.data?.results) {
          handleCloseModal();
          notification.success({
            message:
              'If the email provided exists in our system, you will receive an email shortly.',
          });
        }
        setbuttonLoading(false);
      });
    },
  });

  return (
    <div style={{ padding: '0 30px' }}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label htmlFor='email'>Email:</label>
          <Input
            size='large'
            prefix={<MailOutlined />}
            type='email'
            id='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.email && formik.errors.email && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.email}
            </div>
          )}
        </div>
        <br />

        <Button type='primary' loading={buttonLoading} htmlType='submit'>
          Recover
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;
