import React, { useEffect, useRef, useState } from 'react';
import { Steps } from '../Steps/Steps';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Book.css';
import * as moment from 'moment';
import axiosInstance from '../../../../utils/axios';
import { user } from '../../../../store/user';
import { useRecoilState } from 'recoil';
import { Input, Button, Select, notification } from 'antd';
import { currenices } from '../../../../utils/currency';
import dayjs from 'dayjs';

const cardTypes = [
  { type: 'VI', name: 'Visa' },
  { type: 'MC', name: 'MasterCard' },
  { type: 'AX', name: 'AmericanExpress' },
  { type: 'BC', name: 'BankCard' },
  { type: 'BL', name: 'CarteBleu' },
  { type: 'CB', name: 'CarteBlanche' },
  { type: 'DN', name: 'DinersClub' },
  { type: 'DS', name: 'DiscoverCard' },
  { type: 'EC', name: 'EuroCard' },
  { type: 'JC', name: 'JapaneseCreditBureauCreditCard' },
  { type: 'TP', name: 'UniversalAirTravelCard' },
  { type: 'CU', name: 'ChinaUnionPay' },
  { type: 'MA', name: 'MaestroCard' },
];

const RoomData = ({ room, goNext, paymentType }) => {
  const promoRef = useRef(null);
  const [promo, setPromo] = useState(false);

  useEffect(() => {
    return () => setPromo(false);
  }, []);

  return (
    <div>
      <div className="book-room">
        <div className="book-room-left">
          <>
            <h3>{room.hotel_name}</h3>
            <p>{room.product_name}</p>
          </>

          <div style={{ height: '50px' }} />

          <>
            <p>
              Accommodation cost:{' '}
              <span>
                {currenices[room.currency].symbol}
                {room.rate}
              </span>
            </p>
            <p>
              Service fee:{' '}
              <span>
                {currenices[room.currency].symbol}
                {room.service_fee}
              </span>
            </p>
            <p>
              Tax & Fees:{' '}
              <span>
                {currenices[room.currency].symbol}
                {room.taxes_and_fees}
              </span>
            </p>
            <p>
              Discount code {promo ? '(10%)' : null} :{' '}
              <span>
                -{currenices[room.currency].symbol}
                {promo ? (room.total_price * 0.1).toFixed(2) : 0}
              </span>
            </p>
            <h4>
              Final amount:{' '}
              <span>
                {currenices[room.currency].symbol}
                {promo
                  ? (room.total_price - room.total_price * 0.1).toFixed(2)
                  : room.total_price}
              </span>
            </h4>
          </>
        </div>

        <div style={{ width: '50px' }} />

        <div className="book-room-right">
          <>
            <div style={{ marginTop: '23px' }}>
              <div style={{ marginBottom: '10px' }} className="check">
                Book date: {dayjs(room.date).format('MM-DD-YYYY')}
              </div>
              <div className="check">
                Check in time:{' '}
                {moment(room.check_in_time, 'HH:mm:ss').format('h:mm A')}
              </div>
              <div className="check">
                Check out time:{' '}
                {moment(room.check_out_time, 'HH:mm:ss').format('h:mm A')}
              </div>
            </div>

            <div style={{ height: '43px' }} />

            <p>
              Payment type:{' '}
              <span style={{ color: 'black' }}>{paymentType}</span>
            </p>
            <p>Max number of guests: {room.max_guests_count}</p>

            <a href="/booking-policy" target="_blank">
              Booking policy
            </a>
          </>
        </div>
      </div>

      <div className="promo">
        <div style={{ display: 'flex' }}>
          <Input
            ref={promoRef}
            size="middle"
            placeholder="Promo Code"
            style={{ borderRadius: '0', color: 'black' }}
          />

          <Button
            type="primary"
            onClick={() => {
              if (promoRef.current.input.value === 'PRIORITYPASS2023') {
                setPromo(true);
              } else {
                notification.info({ message: 'Invalid promo code.' });
              }
            }}
            style={{ borderRadius: 0 }}
          >
            Apply
          </Button>
        </div>
        <Button type="primary" onClick={goNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

const SuccessBook = ({ handleCloseModal }) => {
  return (
    <div className="book-complete">
      <div className="circle" />
      <h2>Thank you for booking with us!</h2>
      <p>You will recieve an email shortly with booking details.</p>

      <Button onClick={handleCloseModal}>Close</Button>
    </div>
  );
};

export const Book = ({ book, handleCloseModal, hotel }) => {
  const [steps, setSteps] = useState(0);
  const [times, setTimes] = useState([]);
  const [number, setnumber] = useState('');
  const [expiration, setexpiration] = useState('');
  const [security_code, setsecurity_code] = useState('');
  const [brand, setbrand] = useState('');

  const userData = useRecoilState(user);
  const [buttonLoading, setbuttonLoading] = useState(false);

  const initialValues =
    book.payment_type === 'credit_card'
      ? {
          email: userData[0].email,
          firstname: userData[0].firstname,
          lastname: userData[0].lastname,
          phone: userData[0].phone,
          client_message: '',
          arrival_time: '',
          holder_full_name: '',
        }
      : {
          email: userData[0].email,
          firstname: userData[0].firstname,
          lastname: userData[0].lastname,
          phone: userData[0].phone,
          client_message: '',
          arrival_time: '',
        };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      phone: Yup.string().required('Phone number is required'),
      client_message: Yup.string().required('A message is required'),
      arrival_time: Yup.string().required('Arrival time is required'),
      holder_full_name: Yup.string().when([], {
        is: () => book.payment_type === 'credit_card',
        then: () =>
          Yup.string().required('Full name of card holder is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
    }),
    onSubmit: (values) => {
      const data = bookObj(values);
      setbuttonLoading(true);
      // Handle form submission
      axiosInstance.post(`api/v1/hotels/booking`, data).then((res) => {
        if (res?.data?.results) {
          goNext();
        }

        setbuttonLoading(false);
      });
    },
  });

  useEffect(() => {
    getTimes();
  }, []);

  const cardNumberValidator = ({ cardNumber, cardType, errorMessages }) => {
    const getCardType = cardTypes.find(
      (cn) => cn.name.toLowerCase() === cardType.displayName.toLowerCase()
    );
    setbrand(getCardType.type);
    setnumber(cardNumber);
  };

  const expiryValidator = ({ expiryDate }) => {
    setexpiration(`${expiryDate.year}-${expiryDate.month}`);
  };

  const cvcValidator = ({ cvc }) => {
    setsecurity_code(cvc);
  };

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({ cardNumberValidator, expiryValidator, cvcValidator });

  const goNext = () => {
    setSteps(steps + 1);
  };

  const getTimes = () => {
    const startTime = moment(book.check_in_time, 'h:mm A');
    const endTime = moment(book.check_out_time, 'h:mm A');

    const timeArray = [];

    let currentTime = startTime.clone();

    while (currentTime.isSameOrBefore(endTime)) {
      timeArray.push({
        value: currentTime.format('h:mm'),
        label: currentTime.format('h:mm A'),
      });
      currentTime.add(1, 'hour');
    }

    setTimes(timeArray);
  };

  const paymentType =
    book.payment_type === 'pay_at_hotel'
      ? 'Pay at the hotel'
      : 'Pay by credit card';

  const bookObj = (values) => {
    if (book.payment_type === 'credit_card') {
      return {
        ...book,
        hotel,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
        client_message: values.client_message,
        arrival_time: values.arrival_time,
        credit_card: {
          holder_full_name: values.holder_full_name,
          security_code,
          number,
          expiration,
          brand,
        },
      };
    }

    return {
      ...book,
      hotel,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phone: values.phone,
      client_message: values.client_message,
      arrival_time: values.arrival_time,
    };
  };

  const isBookingCardVlidation =
    book.payment_type === 'credit_card'
      ? !security_code || !brand || !expiration || !number
      : true;

  return (
    <div>
      <Steps steps={steps} />

      {steps === 0 ? (
        <RoomData room={book} goNext={goNext} paymentType={paymentType} />
      ) : steps === 1 ? (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '30px' }}>
          <div>
            <label htmlFor="firstname">First name:</label>
            <Input
              size="large"
              id="firstname"
              name="firstname"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched.firstname && formik.errors.firstname && (
              <div style={{ fontSize: '15px', color: 'red' }}>
                {formik.errors.firstname}
              </div>
            )}
          </div>
          <br />
          <div>
            <label htmlFor="lastname">Last name:</label>
            <Input
              size="large"
              id="lastname"
              name="lastname"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched.lastname && formik.errors.lastname && (
              <div style={{ fontSize: '15px', color: 'red' }}>
                {formik.errors.lastname}
              </div>
            )}
          </div>
          <br />
          <div>
            <label htmlFor="email">Email:</label>
            <Input
              size="large"
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched.email && formik.errors.email && (
              <div style={{ fontSize: '15px', color: 'red' }}>
                {formik.errors.email}
              </div>
            )}
          </div>

          <br />
          <div>
            <label htmlFor="phone">Phone:</label>
            <Input
              size="large"
              type="phone"
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched.phone && formik.errors.phone && (
              <div style={{ fontSize: '15px', color: 'red' }}>
                {formik.errors.phone}
              </div>
            )}
          </div>
          <br />
          <div>
            <label htmlFor="client_message">Message:</label>
            <Input.TextArea
              size="large"
              rows={2}
              id="client_message"
              name="client_message"
              value={formik.values.client_message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched.client_message && formik.errors.client_message && (
              <div style={{ fontSize: '15px', color: 'red' }}>
                {formik.errors.client_message}
              </div>
            )}
          </div>
          <br />
          <div>
            <label htmlFor="arrival_time">Arrival time:</label>
            <Select
              defaultValue={formik.values.arrival_time}
              style={{
                width: '100%',
              }}
              id="arrival_time"
              name="arrival_time"
              onChange={(e) => formik.setFieldValue('arrival_time', `${e}:00`)}
              onBlur={formik.handleBlur}
              options={times}
            />
            {formik.touched.arrival_time && formik.errors.arrival_time && (
              <div style={{ fontSize: '15px', color: 'red' }}>
                {formik.errors.arrival_time}
              </div>
            )}
          </div>
          <br />
          <div>
            {book.payment_type === 'credit_card' && (
              <>
                <div>
                  <label htmlFor="holder_full_name">
                    Full name of card holder:
                  </label>
                  <Input
                    size="large"
                    type="holder_full_name"
                    id="holder_full_name"
                    name="holder_full_name"
                    value={formik.values.holder_full_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.holder_full_name &&
                    formik.errors.holder_full_name && (
                      <div style={{ fontSize: '15px', color: 'red' }}>
                        {formik.errors.holder_full_name}
                      </div>
                    )}
                </div>
                <br />
                <label htmlFor="cc">Credit card:</label>
                <div id="cc">
                  <PaymentInputsWrapper {...wrapperProps}>
                    <svg {...getCardImageProps({ images })} />
                    <input {...getCardNumberProps()} />
                    <input {...getExpiryDateProps()} />
                    <input {...getCVCProps()} />
                  </PaymentInputsWrapper>
                </div>
              </>
            )}
          </div>

          <Button
            type="primary"
            disabled={!formik.isValid && isBookingCardVlidation}
            loading={buttonLoading}
            htmlType="submit"
            style={{ marginTop: '30px' }}
          >
            Book now
          </Button>
        </form>
      ) : (
        <SuccessBook handleCloseModal={handleCloseModal} />
      )}
    </div>
  );
};
