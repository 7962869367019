import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, theme } from 'antd';

import './Faq.css';
import { Search } from '../Landing/components/Search/Search';

export const Faq = () => {
  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'FAQ',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  /*
  const getItems = (panelStyle) => [
    {
      key: "1",
      label: "What is Nappr exactly?",
      children: (
        <p>
          Nappr is a marketplace that allows people to find and book on-demand nap spaces from a variety of locations. We're currently up and running in New York City but we
          promise to be in more places soon!
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: "Hmm, ok. But why?",
      children: (
        <p>
          Because napping has so many amazing benefits and it's often inaccessible for people when they need it the most! Whether you're in the middle of a rough workday or 
          an extremely long day of flying, we believe that you deserve a comfortable bed to help you be way more productive and refreshed. And unless you're at 
          a super sexy startup or a bed factory, we're thinking that you probably don't have the means to access this right now.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: "How does it work?",
      children: (
        <p>
          It's super simple, we promise. If you want to take a nap, you simply search for available places in your area, book a time slot, and then go there to nap! That's it.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: "Can anyone be a host?",
      children: (
        <p>
          Not quite yet, but we're getting there! At the moment only businesses can host their spaces on here (e.g. hotels, spas, etc.) but we do have grand plans to expand 
          hosting capabilities to consumers as well. That being said, if you would love to host on our platform as a consumer we'd love to hear about it! Please <a href='mailto:info@nappr.io' target='_blank'>email us</a> the deets and we'll be in touch.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "5",
      label: "... so any business can host on here?",
      children: (
        <p>Correctomundo! As long as they have a spare room and bed of course. We <i>do</i> want to you to get a refreshing snooze in after all.</p>
      ),
      style: panelStyle,
    },
    {
      key: "6",
      label: "How long can I nap for exactly?",
      children: (
        <p>
          Everybody is a unique napper in their own way, shape or snore (hopefully not the last part. Just kidding.) so we don’t want to restrict how long you can take one for. 
          That being said, we offer spaces for as little as 4 hours and up to 8 hours at the moment.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "7",
      label: "How much does it cost?",
      children: (
        <p>
          We think that the amazing (extra) sleep that you'll get is priceless, but alas, there are a few dollars and cents at the end of the day. This is a capitalist society 
          after all. There are a variety of price points on our platform and you'll see all of the costs upfront whenever you click on a listing.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "8",
      label: "Help! I wasnt able to sleep during my time slot?!",
      children: (
        <p>
          That is definitely a bummer. If the bed type wasn't your fave or you just had too much on your mind at the time, we feel ya...it happens. If the host listing was 
          inaccurate in any way possible, please <a href='mailto:info@nappr.io' target='_blank'>email us</a> to let us know.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "9",
      label: "Do you guys sell any physical products?",
      children: <p>Not yet. But the sky is the limit!</p>,
      style: panelStyle,
    },
    {
      key: "10",
      label: "Where are your Terms of Service, Privacy Policy and Booking Policy?",
      children: (
        <p>
          Great question! You can find all of our terms <a href='http://www.nappr.io/terms' target='_blank'>here</a>, our privacy policy <a href='http://www.nappr.io/privacy/' 
          target='_blank'>here</a> and our booking policy <a href='https://nappr.io/booking-policy' target='_blank'>here</a>. 
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "11",
      label:
        "I'm afraid of taking a nap in the middle of my shift. Make me feel better about this.",
      children: (
        <p>
          We totally understand the concern since we're all busy and need to get some work done. But let's think about the alternatives here (mainly, coffee and energy drinks).
          These are a short-term workaround that may give you a temporary boost, but they're essentially forcing your body to stay awake and they often lead to burnout, which 
          is no fun for anyone. Research shows that even a 30-minute power nap can make you more productive and significantly improve your brain activity, so why not give this 
          much healthier option a shot?
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "12",
      label: "How do I know the bed is clean?",
      children: (
        <p>
          That's part of a host's terms of service that they're agreeing to! If you ever find one that isn't though, please <a href='mailto:info@nappr.io' target='_blank'>email us</a> so that we can look into this further.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "13",
      label: "Can I talk to my host beforehand?",
      children: (
        <p>
          Sure thing! Feel free to reach out to the host to ensure that all of your concerns are addressed.
        </p>
      ),
      style: panelStyle,
    },
  ];
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  */
  return (
    <div className='faq-page'>
      <div className='hotels-search'>
        <Search />
      </div>
      <div className='faq-page-content'>
        <h1>FAQ</h1>
        <h2>What is Nappr exactly?</h2>
        <span>
          We're a marketplace that offers 4 to 10-hour hotel rates to help people catch up on rest and meet the demands of their unique schedules.
        </span>
        <h2>How many hotels do I have to choose from?</h2>
        <span>
          We have over 1,030 hotels on our platform spanning 28 different countries + Puerto Rico!
        </span>
        <h2>Cool - which countries in particular?</h2>
        <span>
          Right now we have properties in Australia, Brazil, Canada, Colombia, Denmark, France, Germany, India, Indonesia, Ireland, Israel, Italy, Malaysia, Mexico, Netherlands, Oman, Pakistan, the Philippines, Puerto Rico, Qatar, Scotland, South Africa, St. Maarten, Tanzania, Thailand, Turkey, the United Arab Emirates, the United Kingdom and the United States!
        </span>
        <h2>Hmm, ok. But why?</h2>
        <span>
          For one, many people on-the-go often lack the means to rest and recharge in the middle of a hectic day. Whether you're stranded after an early red-eye arrival, stressed out at the office, cramming for a brutal exam or visiting family at a hospital, we are here to help you relax when you need to the most.
          <br/><br/>
          Also - our rates are typically 30-50% cheaper than a hotel's overnight rates! Who wants to pay full price if you're only going to be there for 4 hours? (Probably not you)
        </span>
        <h2>How does it work?</h2>
        <span>
          It's super simple, we promise. If you want to book a place, you simply search for available hotels in your area, pick the time slot of your choice, and then go there to nap and/or relax! That's it. Many hotels offer more than one time block so just make sure that you pick the one that best fits your needs.
        </span>
        <h2>Can anyone be a host?</h2>
        <span>
          At the moment only businesses can host their spaces (e.g. hotels, spas, etc.) but we do have grand plans to expand hosting capabilities to consumers as well. If you're a business and are interested in hosting on our platform please reach out and we'll talk!
        </span>
        <h2>How long can I book a place for?</h2>
        <span>
          We offer spaces for as little as 4 hours and up to 10 hours at various times during the day. You'll have access to your room for the entirety of the block and can arrive at the time of your choosing.
        </span>
        <h2>How far in advance can I book?</h2>
        <span>
          As far in advance as you'd like! You can also book something spontaneously as well, even if it's in the middle of a current time slot.
        </span>
        <h2>Can I use all of the hotel amenities like an overnight guest?</h2>
        <span>
          YES!! You will have access to any of the hotel's offerings (e.g. Pool, Exercise Room, Business Center, etc.) at your leisure. Neat huh?
        </span>
        <h2>How much does it cost?</h2>
        <span>
          We think that the amazing extra rest that you'll get is priceless, but alas. There are a variety of price points on our platform and you'll see all of the costs upfront whenever you view a listing.
        </span>
        <h2>Can I talk to the hotel beforehand?</h2>
        <span>
          Sure thing! Feel free to reach out to the hotel to ensure that all of your concerns are addressed.
        </span>
        <h2>Help! I wasnt able to sleep during my time slot?!</h2>
        <span>
          That is definitely a bummer. If the bed type wasn't your fave or you just had too much on your mind at the time, we feel ya...it happens. If the host listing was inaccurate in any way possible, please contact us to let us know.
        </span>
        <h2>Do you guys sell any physical products?</h2>
        <span>
          Not yet. But the sky is the limit!
        </span>
        <h2>
          I'm afraid of taking a nap in the middle of my shift. Make me feel better about this.
        </h2>
        <span>
          We totally understand the concern. But let's think about the alternatives here (mainly, coffee and energy drinks). These are a short-term workaround that may give you a temporary boost, but they're essentially forcing your body to stay awake and they often lead to burnout, which is no fun for anyone. Research shows that even a 30-minute power nap can make you more productive and significantly improve your brain activity, so why not give this a shot?
        </span>
        <h2>How do I know the bed is clean?</h2>
        <span>
          That's part of a host's terms of service that they're agreeing to! If you ever find one that isn't though, please contact us so that we can look into this further.
        </span>
      </div>
    </div>
  );
};
