import React, { useState, useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import * as Yup from 'yup';
import './ContuctUs.css';
import { Search } from '../Landing/components/Search/Search';
import { useFormik } from 'formik';
import { Button, Input, notification, Select } from 'antd';
import axiosInstance from '../../utils/axios';

export const ContactUs = () => {
  const [buttonLoading, setbuttonLoading] = useState(false);

  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'ContactUs',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: '',
      type: 'general',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      name: Yup.string().required('Name is required'),
      message: Yup.string().required('Message is required'),
      type: Yup.string().required('Type is required'),
    }),
    onSubmit: (values) => {
      setbuttonLoading(true);
      // Handle form submission
      axiosInstance.post('api/v1/contactus', values).then((res) => {
        if (res?.data?.results) {
          notification.success({
            message: 'Email sent successfully!',
          });
          formik.resetForm();
        }
        setbuttonLoading(false);
      });
    },
  });
  return (
    <div>
      <div className='hotels-search'>
        <Search />
      </div>
      <div class='contact-us'>
        <div style={{ width: '40%' }}>
          <h2>Contact us</h2>
          <p>
            Questions, comments or concerns? Our dedicated staff is here to
            help. We will try to respond as quickly as possible!
          </p>
          <div className='contact-infos'>
            <div>Web</div>
            <span>
              <a href='/' target='_blank'>
                www.nappr.io
              </a>
            </span>
          </div>

          <div className='contact-infos'>
            <div>E-Mail</div>
            <span>
              <a href='mailto:info@nappr.io' target='_blank'>
                info@nappr.io
              </a>
            </span>
          </div>
        </div>

        <div style={{ width: '49%' }} class='contact-form'>
          <div class='row'>
            <h2>Contact form</h2>
          </div>
          <div class='row input-container'>
            <div>
              <label htmlFor='email'>Name:</label>
              <Input
                size='large'
                type='name'
                id='name'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.touched.name && formik.errors.name && (
                <div style={{ fontSize: '15px', color: 'red' }}>
                  {formik.errors.name}
                </div>
              )}
            </div>

            <br />

            <div>
              <label htmlFor='email'>Email:</label>
              <Input
                size='large'
                type='email'
                id='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.touched.email && formik.errors.email && (
                <div style={{ fontSize: '15px', color: 'red' }}>
                  {formik.errors.email}
                </div>
              )}
            </div>

            <br />

            <div>
              <label htmlFor='type'>
                Type:
                <br />
                <Select
                  defaultValue={formik.values.type}
                  style={{
                    width: '100%',
                  }}
                  name='type'
                  onChange={(e) => formik.setFieldValue('type', e)}
                  onBlur={formik.handleBlur}
                  options={[
                    { value: 'general', label: 'General Question' },
                    { value: 'partnership', label: 'Partnership Outreach' },
                    { value: 'bookingissue', label: 'Booking Issue' },
                    { value: 'bugreport', label: 'Bug Report' },
                    { value: 'other', label: 'Other' },
                  ]}
                />
              </label>

              {formik.touched.type && formik.errors.type && (
                <div style={{ fontSize: '15px', color: 'red' }}>
                  {formik.errors.reason}
                </div>
              )}
            </div>

            <br />

            <div>
              <label htmlFor='email'>Message:</label>
              <Input.TextArea
                size='large'
                rows={4}
                type='message'
                id='message'
                name='message'
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.touched.message && formik.errors.message && (
                <div style={{ fontSize: '15px', color: 'red' }}>
                  {formik.errors.message}
                </div>
              )}
            </div>

            <br />

            <Button
              type='primary'
              loading={buttonLoading}
              onClick={() => formik.handleSubmit()}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
