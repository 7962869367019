import { Spin } from 'antd';
import React from 'react';
import './Spinner.css';

export const Spinner = ({ size }) => {
  return (
    <div className='spin-loader-container'>
      <Spin size={size} />
    </div>
  );
};
