import { atom } from 'recoil';

export const user = atom({
  key: 'user',
  default: null,
});

export const userLocation = atom({
  key: 'location',
  default: null,
});
